<template>
  <Fragment>
    <div :class="isLoading ? 'loading' : 'noLoading'">
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-click-outside="onLostFocus" class="dropdown">
      <input
        ref="dropdownInput"
        :disabled="disabled"
        autocomplete="off"
        v-model="text"
        v-on:keyup="onKeyUp"
        v-on:keydown="onKeyDown"
        @focus="onFocus"
        @blur="onBlur"
        @change="onTextUpdate"
        @keyup.enter="$emit('keyupEnter', $event)"
        @keydown="$emit('keydown', $event)"
        :class="{ error: isError, 'not-allowed': disabled }"
        class="dropdown-input form-control"
        type="text"
        :style="`height:${inputHeight}`"
        :maxlength="maxlength"
        :placeholder="placeHolder"
      />
      <span class="fa arrow dropdown-arrow" @click="onArrowClick" />
      <div
        ref="dropdownList"
        v-show="(isDroppedDown || isLoadingData) && isFocused"
        class="dropdown-list"
      >
        <template v-if="isLoadingData">
          <div class="dropdown-item">
            <div class="text-center w-100 text-warning">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(item, index) in items"
            @click="selectItem(item)"
            @mousemove="onMouseMove(index)"
            :key="item.Value"
            class="dropdown-item"
            :class="{
              'dropdown-item-highlight': index === highlightedItemIndex,
            }"
          >
            {{ item.Key }}
          </div>
        </template>
      </div>
      <ListSearchBrowser
        v-if="showingBrowser"
        class="default-font-Color"
        ref="listSearchBrowser"
        :isOverlapping="isOverlapping"
        :initialSearchProvider="searchProvider"
        :searchInfo="browserSearchInfo"
        :initialSelection="lastSelected"
        :stockpointId="currentStockpointId"
        :costCenterId="costCenterId"
        @onClose="closeBrowser"
        @onItemSelected="selectItem($event)"
        :isItemPopup="isItemPopup"
        :requisitionBrowserOption="requisitionBrowserOption"
      >
      </ListSearchBrowser>
    </div>
  </Fragment>
</template>

<script>
import $ from 'jquery'
import { Fragment } from 'vue-fragment'
import ListSearchBrowser from '../shared/ListSearchBrowser.vue'
import {
  listSearchOptions,
  searchById,
  searchCostCodes,
  searchCostCodesById
} from '../../helpers/listSearch.js'
import { uuid } from 'vue-uuid'

export default {
  name: 'ListSearchComboBox',
  components: {
    Fragment,
    ListSearchBrowser
  },
  props: {
    listTypeId: {
      type: Number,
      required: true
    },
    addNewOption: {
      type: Boolean,
      default: false
    },
    addBlankOption: {
      type: Boolean,
      default: true
    },
    addAllOption: {
      type: Boolean,
      default: false
    },
    isTWEntitySearch: {
      type: Boolean,
      default: false
    },
    selectedId: {
      type: String,
      default: ''
    },
    selectedKey: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    stockpointId: {
      type: String,
      default: null
    },
    inputHeight: {
      type: String,
      default () {
        return '36px'
      }
    },
    costCenterId: {
      type: String,
      default: ''
    },
    isError: {
      type: Boolean,
      default: false
    },
    isInitEvent: {
      type: Boolean,
      required: false,
      default: true
    },
    tabFocusInView: {
      type: Boolean,
      required: false,
      default: false
    },
    isWorkOrderTaskDescription: {
      type: Boolean,
      required: false,
      default: false
    },
    isTransferPage: {
      type: Boolean,
      required: false,
      default: false
    },
    isPickPage: {
      type: Boolean,
      required: false,
      default: false
    },
    isPOPage: {
      type: Boolean,
      required: false,
      default: false
    },
    isFuelCardPage: {
      type: Boolean,
      required: false,
      default: false
    },
    isSchedulingCart: {
      type: Boolean,
      required: false,
      default: false
    },
    isTaskPage: {
      type: Boolean,
      required: false,
      default: false
    },
    itemNumber: {
      type: String,
      default: ''
    },
    isItemPopup: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: String,
      required: false,
      default: ''
    },
    costCenterDescription: {
      type: String,
      required: false,
      default: ''
    },
    costCodeDescription: {
      type: String,
      required: false,
      default: ''
    },
    jobStockOption: {
      type: Boolean,
      default: false
    },
    placeHolder: {
      type: String,
      required: false,
      default: ''
    },
    requisitionBrowserOption: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      searchProvider: null,
      resultsList: [],
      items: [],
      lastSelected: null,
      canSearch: true,
      showingBrowser: false,
      lastSearchInfo: null,
      isTextUpdated: false,
      textUpdatedAt: null,
      externalValue: null,
      lastKeyUp: null,
      selectedItem: null,
      isDroppedDown: false,
      timeout: null,
      isLoading: false,
      isLoadingData: false,
      text: '',
      inputSource: 2,
      highlightedItemIndex: -1,
      browserSearchInfo: null,
      isInitialized: false,
      isFocused: false,
      arrowBtnClicked: false,
      isDdlArrowBtnClicked: false,
      descriptionList: []
    }
  },
  mounted: async function () {
    this.searchProvider = {
      listTypeId: this.listTypeId
    }
    await this.loadDescriptions()
    if (this.selectedId && this.selectedId.length > 0) {
      await this.setSelectedValue(this.selectedId, this.selectedKey)
    } else {
      this.resetItems()
      if (this.addAllOption) {
        this.selectedItem = this.items[0]
        this.raiseSelectedItemChanged()
      }
      if (this.jobStockOption) {
        this.selectedItem = this.items[0]
        this.raiseSelectedItemChanged()
      }
    }
    this.isInitialized = true
  },
  watch: {
    selectedKvp (kvp) {
      if (kvp) {
        this.text = kvp.Key
      }
      if (this.isTransferPage) {
        this.$emit('setTransferDataWrapper')
      }
      if (this.isPickPage) {
        this.$emit('setPickDataWrapper')
      }
      if (this.isPOPage) {
        this.$emit('setPODataWrapper')
      }
    },
    highlightedItemIndex () {
      this.$nextTick(() => {
        if (!this.isDescriptionList) {
          this.scrollToIndex()
        }
      })
    },
    items () {
      this.highlightedItemIndex = -1
    },
    async selectedId (newVal) {
      if (newVal && newVal.length > 0) {
        await this.setSelectedValue(newVal)
      } else {
        const isTextClear = !(this.text?.length <= 2)
        this.clearItemsAndSelection(true, isTextClear)
      }
      this.isInitialized = false
    },
    listTypeId (value) {
      this.searchProvider = {
        listTypeId: value
      }
    }
  },
  methods: {
    onFocus (event) {
      this.isFocused = true
      this.tabFocusInView &&
        event.target.scrollIntoView({ block: 'end', inline: 'end' })
    },
    onBlur () {
      if ((this.isWorkOrderTaskDescription || this.isDescriptionList) && !this.isDroppedDown) {
        this.onLostFocus()
      }
      setTimeout(() => {
        if (this.arrowBtnClicked) {
          this.arrowBtnClicked = false
          return
        }
        // this.isFocused = false
        this.isDroppedDown = false
      }, 200)
    },
    getSelectedValue () {
      if (!this.isValidSelection(this.selectedKvp)) {
        return null
      }

      return this.selectedKvp.Value
    },
    async setSelectedValue (value, key = null) {
      if (
        value !== null &&
        value !== undefined &&
        this.selectedKvp !== null &&
        this.selectedKvp !== undefined &&
        this.selectedKvp.Value === value
      ) {
        return
      }

      this.externalValue = value
      this.canSearch = true
      this.lastSelected = null

      // If setting NULL, then set to "no selection"
      if (
        value === null ||
        value === undefined ||
        value === '00000000-0000-0000-0000-000000000000'
      ) {
        const isTextClear = !(this.text?.length <= 2)
        this.clearItemsAndSelection(this.isInitialized, isTextClear)
        return
      }
      let filteredResults = []
      if (this.resultsList !== null && this.resultsList.length > 0) {
        var testResults = this.resultsList[0]
        if (testResults !== undefined && testResults !== null && testResults.Value !== undefined && testResults.Value === null) {
          // Else look in the loaded list for the item
          filteredResults = this.resultsList.filter((x) => x.Value === value)
        }
      }
      let kvp = null
      if (filteredResults.length > 0) {
        kvp = filteredResults[0]
        this.selectedItem = kvp
        this.raiseSelectedItemChanged()
        this.lastSelected = kvp
        return
      }

      // Else find the list item
      if (this.isCostCodeList) {
        if (this.costCodeDescription) {
          kvp = {
            Key: this.costCodeDescription,
            Value: value
          }
        } else {
          const { costCode } = await searchCostCodesById(
            value,
            this.currentStockpointId,
            this.costCenterId
          )
          kvp = {
            Key: costCode?.Description,
            Value: costCode?.CostCodeId
          }
        }
      } else {
        if (this.isDescriptionList) {
          kvp = this.searchDescriptionById(value)
        } else if (this.costCenterDescription) {
          kvp = {
            Key: this.costCenterDescription,
            Value: value
          }
        } else if (key !== null) {
          kvp = {
            Key: key,
            Value: value
          }
        } else {
          kvp = await searchById(value, this.searchProvider.listTypeId)
        }
      }

      if (kvp !== undefined && kvp !== null) {
        this.resultsList.length = 0
        if (this.isDescriptionList) {
          this.addDescriptions()
        } else {
          if (this.isFuelCardPage) {
            kvp = {
              Key: this.itemNumber,
              Value: value
            }
          }
          this.resultsList.push(kvp)
        }
        this.resetItems()
        this.selectedItem = kvp
        this.lastSelected = kvp
      } else {
        this.selectedItem = null
      }
      if (!this.isInitialized || this.isInitEvent) {
        this.raiseSelectedItemChanged()
      }
    },
    clearItemsAndSelection (raiseChangedEvent = true, isTextClear = true) {
      this.resultsList = []
      this.resetItems()
      this.selectedItem = null
      this.lastSelected = null
      if (isTextClear) {
        this.text = ''
      }
      if (raiseChangedEvent) {
        this.raiseSelectedItemChanged()
      }
    },
    resetItems () {
      this.items.length = 0
      if (this.addBlankOption) {
        this.items.push(listSearchOptions.blank)
      }

      if (this.addNewOption) {
        this.items.push(listSearchOptions.new)
      }
      if (this.jobStockOption) {
        const userId = localStorage.getItem('UserId')
        if (localStorage.getItem('jobStockLocation') != null &&
         JSON.parse(localStorage.getItem('jobStockLocation')).userId == userId) {
          const item = JSON.parse(localStorage.getItem('jobStockLocation'))
          this.items.push(item)
        } else {
          this.items.push(listSearchOptions.jobStockNewOption)
        }
      }
      if (this.addAllOption) {
        this.items.push(listSearchOptions.all)
      }
      if (this.resultsList !== null && this.resultsList.length > 0) {
        this.items.push(...this.resultsList)
        if (this.jobStockOption) {
          this.items.shift()
        }
      }
      if (!this.isDescriptionList) {
        this.items.push(listSearchOptions.browse)
      }
    },
    raiseSelectedItemChanged () {
      let item = this.selectedItem
      let isItemNull =
        this.selectedItem === null || this.selectedItem === undefined
      if (isItemNull) {
        if (this.addBlankOption) {
          item = { Key: '\xa0', Value: null }
        }
        if (this.addAllOption) {
          item = {
            Key: '--All--',
            Value: '00000000-0000-0000-0000-000000000000'
          }
        }
        if (this.jobStockOption) {
          item = {
            Key: '',
            Value: '00000000-0000-0000-0000-000000000000'
          }
        }
      }
      isItemNull = item === null || item === undefined
      if (isItemNull) {
        this.$emit('hasValue', false)
      } else {
        localStorage.setItem('workOrderAssignedToValue', item.Value)
        this.$emit('onIdSelected', item.Value)
        this.$emit('onTextSelected', item.Key)
        this.$emit('onItemSelected', item)
        this.$emit('hasValue', true)
      }
    },
    isValidSelection (kvp) {
      return (
        kvp !== null &&
        kvp !== undefined &&
        kvp.Value !== listSearchOptions.new.Value &&
        kvp.Value !== listSearchOptions.blank.Value &&
        kvp.Value !== listSearchOptions.all.Value &&
        kvp.Value !== listSearchOptions.browse.Value
      )
    },
    selectItem (item) {
      this.canSearch = true
      this.isDroppedDown = false
      this.isFocused = false
      if (this.isBlankSelected(item) && this.lastSelected === null) {
        return
      }
      if (this.isBrowseSelected(item) && !this.isFuelCardPage) {
        this.showBrowser()
        return
      }
      if (this.isFuelCardPage) {
        this.$emit('openItemBrowser', item)
      }
      if (this.isTaskPage) {
        this.$emit('isLocationValidate', item)
      }
      if (this.isSchedulingCart) {
        this.$emit('updateCostCenter', item)
      }
      if (this.isAllSelected(item)) {
        this.selectedItem = item
        this.raiseSelectedItemChanged()
        return
      }
      const selected = item
      this.resultsList = []
      if (this.isValidSelection(selected)) {
        if (this.isDescriptionList) {
          this.addDescriptions()
        } else {
          if (this.jobStockOption) {
            const userId = localStorage.getItem('UserId')
            const item = selected
            item.userId = userId
            localStorage.removeItem('jobStockLocation')
            localStorage.setItem('jobStockLocation', JSON.stringify(item))
          }
          this.resultsList.push(selected)
        }
      }

      this.resetItems()

      if (this.isValidSelection(selected)) {
        this.selectedItem = selected
        this.lastSelected = selected
      } else {
        this.selectedItem = null
        this.lastSelected = null
      }
      this.raiseSelectedItemChanged()
      this.lastSearchInfo = null
    },
    onTextUpdate () {
      this.isTextUpdated = true
      this.textUpdatedAt = new Date()
      if (this.text === '' || this.text.length <= 2) {
        this.onFocus()
        this.resultsList = []
        this.resetItems()
        this.$emit('onIdSelected', null)
        return
      }
      this.$emit('onTextEnter', this.text)
    },
    selectHighlightedItem () {
      const kvp = this.items[this.highlightedItemIndex]
      this.selectItem(kvp)
    },
    tryToSelectHighlightedItem () {
      if (this.highlightedItemIndex !== -1) {
        this.selectHighlightedItem()
      } else {
        this.onLostFocus()
      }
    },
    onKeyDown (e) {
      const code = e.code
      this.lastKeyUp = code
      // Tab doesn't fire an onKeyUp event, so handle it here
      if (code === 'Tab') {
        if (!this.isDroppedDown) {
          return
        }
        if (this.isValidFreeformEntry) {
          this.selectFreeformEntry()
        } else {
          this.tryToSelectHighlightedItem()
        }
      }

      if (code === 'Escape') {
        if (!this.isDroppedDown) {
          return
        }
        this.canSearch = true
        this.isDroppedDown = false
        this.resultsList.length = 0

        if (this.lastSelected !== null) {
          if (
            this.addAllOption &&
            this.selectedKvp?.Key == listSearchOptions.all.Key
          ) {
            return
          }
          if (this.isDescriptionList) {
            this.addDescriptions()
          } else {
            this.resultsList.push(this.lastSelected)
          }
          this.resetItems()
          this.selectedItem = this.lastSelected
          this.text = this.lastSelected.Key
          this.raiseSelectedItemChanged()
        } else {
          if (
            this.addAllOption &&
            this.selectedKvp?.Key == listSearchOptions.all.Key
          ) {
            return
          }
          this.clearItemsAndSelection()
        }

        this.isTextUpdated = false
        e.preventDefault()
        e.stopPropagation()
      }
      if (this.isWorkOrderTaskDescription || this.isDescriptionList) {
        const key = String.fromCharCode(e.keyCode)
        const allCharacters = /[a-zA-Z0-9-_ `!@#$%^&*()+=[\]{};':"\\|,.<>/?~]/
        if (allCharacters.test(key)) {
          this.isFocused = true
        }
      }
    },
    onKeyUp (e) {
      const code = e.code
      this.lastKeyUp = code

      if (code === 'Enter' || code === 'NumpadEnter') {
        if (!this.isDroppedDown) {
          return
        }
        if (this.isValidFreeformEntry) {
          this.selectFreeformEntry()
          return
        } else {
          this.tryToSelectHighlightedItem()
          return
        }
      }

      if (code === 'ArrowUp') {
        if (!this.isDroppedDown) {
          return
        }
        this.highlightPrevious()
        this.isTextUpdated = false
        return
      }

      if (code === 'ArrowDown') {
        if (!this.isDroppedDown) {
          return
        }
        this.highlightNext()
        this.isTextUpdated = false
        return
      }
      if (code === 'Escape') {
        // Handled in onKeyDown to prevent propagation
        return
      }
      if (code === 'Backspace' &&
        (this.text === '' || this.text.length <= 2)) {
        if (this.jobStockOption) {
          localStorage.removeItem('jobStockLocation')
          this.clearItemsAndSelection(true, false)
        }
        this.onFocus()
        this.resultsList = []
        this.resetItems()
        this.$emit('onIdSelected', null)
        return
      }

      this.onTextUpdate()

      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      const self = this
      this.timeout = setTimeout(async () => {
        if (self.isTextUpdated && self.canSearch) {
          this.isLoadingData = true
          await self.searchList()
          this.isLoadingData = false
        }
        self.isTextUpdated = false
      }, 500)
    },
    onLostFocus () {
      if (!this.isFocused) {
        return
      }

      this.isFocused = false

      if (this.isValidFreeformEntry) {
        this.selectFreeformEntry()
        return
      }

      this.isDroppedDown = false

      if (this.showingBrowser) {
        return
      }

      const selected = this.selectedKvp
      if (this.isValidSelection(selected)) {
        const raiseChangedEvent =
          !this.lastSelected || this.lastSelected.Value !== selected.Value
        this.lastSelected = selected
        this.filterToLastSelection()
        if (raiseChangedEvent) {
          this.raiseSelectedItemChanged()
        }
      } else if (this.lastSelected !== null) {
        if (
          this.addAllOption &&
          this.selectedKvp?.Key == listSearchOptions.all.Key
        ) {
          return
        }
        this.filterToLastSelection()
        this.selectedItem = this.lastSelected
        this.raiseSelectedItemChanged()
      } else if (selected !== null || this.isTextSet) {
        if (
          this.addAllOption &&
          this.selectedKvp?.Key == listSearchOptions.all.Key
        ) {
          return
        }
        this.clearItemsAndSelection(false)
      }
      this.canSearch = true
    },
    filterToLastSelection () {
      if (this.resultsList === null) {
        this.resultsList = []
      }
      this.resultsList.length = 0
      if (this.isDescriptionList) {
        this.addDescriptions()
      } else {
        this.resultsList.push(this.lastSelected)
      }
      this.resetItems()
      this.text = this.lastSelected.Key
    },
    onSelectedIndexChanged () {
      this.canSearch = true
    },
    async searchList (doShowDropdown = true) {
      this.canSearch = false
      const searchInfo = this.createSearchInfo(this.text)
      if (searchInfo.formattedInput.length <= 2) {
        this.isDescriptionList = false
      }
      if (this.isDescriptionList || searchInfo.formattedInput.length > 2) {
        let error = false
        let searchResults = null
        let searchResult = null
        try {
          searchResults = await this.getSearchResults(searchInfo)
          if (this.isDescriptionList) {
            searchResult = this.searchDescriptions(this.text.trim())
            this.resultsList = searchResult
            this.addDescriptions()
            this.resultsList = searchResult
          } else {
            this.resultsList = searchResults
          }
        } catch {
          error = true
          this.lastSelected = null
          this.resultsList = []
          this.resultsList.length = 0
        }
        this.resetItems()
        if (error) {
          this.isDroppedDown = false
          this.lastSelected = null
          this.text = 'search failed, please try again'
        } else if (searchResults.length > 0) {
          if ((this.lastKeyUp === 'Enter' && searchResults.length === 1) || (this.isDescriptionList)) {
            this.isDroppedDown = true
            if (searchResult && this.isDescriptionList) {
              this.doShowDropDownList(doShowDropdown, searchInfo)
            } else {
              if (!this.isDescriptionList) {
                this.setSelectedValue(searchResults[0].Value)
              }
            }
          } else {
            this.doShowDropDownList(true, searchInfo)
          }
        }
      } else {
        this.lastSearchInfo = null
        this.resultsList.length = 0
        if (searchInfo.originalInput.length === 0) {
          this.lastSelected = null
        }
        this.clearItemsAndSelection(true, false)
        this.isDroppedDown = false
        this.text = searchInfo.originalInput
      }

      this.canSearch = true
    },
    async getSearchResults (searchInfo) {
      let resultProvider = this.searchProvider
      if (this.listTypeId === 10) {
        resultProvider = this.getEntitiesSearchProvider(searchInfo)
      } else if (this.listTypeId === 18) {
        resultProvider = this.getTransferrableEntitiesSearchProvider(searchInfo)
      }

      let result = null
      if (this.isCostCodeList) {
        const { costCodes } = await searchCostCodes(
          searchInfo.formattedInput,
          this.currentStockpointId,
          this.costCenterId
        )
        result = costCodes.map((costCode) => ({
          Key: costCode?.Description,
          Value: costCode?.CostCodeId
        }))
      } else {
        result = await this.$store.dispatch('loadDataByListType', {
          query: searchInfo.formattedInput,
          listTypeId: resultProvider.listTypeId,
          stockpointId: this.currentStockpointId
        })
      }

      this.lastSearchInfo = searchInfo
      return result
    },
    getEntitiesSearchProvider (searchInfo) {
      const currentProvider = this.searchProvider
      if (searchInfo.isEmployeeSearch) {
        currentProvider.listTypeId = 7
      } else if (searchInfo.isLocationSearch) {
        currentProvider.listTypeId = 9
      }
      return currentProvider
    },
    getTransferrableEntitiesSearchProvider (searchInfo) {
      const currentProvider = this.searchProvider
      if (searchInfo.isEmployeeSearch) {
        currentProvider.listTypeId = 37
      } else if (searchInfo.isLocationSearch) {
        currentProvider.listTypeId = 38
      }
      return currentProvider
    },
    createSearchInfo (query) {
      if (query === null || query === undefined) {
        query = ''
      }
      const searchInfo = {
        originalInput: query,
        formattedInput: query,
        isEmployeeSearch: false,
        isLocationSearch: false
      }
      if (this.isTWEntitySearch) {
        const substring = query.substring(0, 2).toUpper()
        if (substring === '/E') {
          searchInfo.isEmployeeSearch = true
          searchInfo.formattedInput = query.substring(2)
        } else if (substring === '/L') {
          searchInfo.isLocationSearch = true
          searchInfo.formattedInput = query.substring(2)
        }
        return searchInfo
      }

      return searchInfo
    },
    scrollToIndex () {
      const targetElement =
        this.$refs.dropdownList?.children[this.highlightedItemIndex] || null

      if (targetElement) {
        const bounds = this.getViewBounds()
        const { top, bottom, height } = targetElement.getBoundingClientRect()

        if (top < bounds.top) {
          return (this.$refs.dropdownList.scrollTop = targetElement.offsetTop)
        } else if (bottom > bounds.bottom) {
          return (this.$refs.dropdownList.scrollTop =
            targetElement.offsetTop +
            targetElement.clientHeight -
            (bounds.height - height))
        }
      }
    },
    getViewBounds () {
      if (this.$refs.dropdownList) {
        return this.$refs.dropdownList.getBoundingClientRect()
      } else {
        return { height: 0, top: 0, bottom: 0 }
      }
    },
    highlightPrevious () {
      let newIndex = this.highlightedItemIndex - 1
      if (newIndex < 0) {
        newIndex = this.items.length - 1
      }
      this.highlightedItemIndex = newIndex
      this.text = this.items[newIndex]?.Key ?? ''
    },
    highlightNext () {
      let newIndex = this.highlightedItemIndex + 1
      if (newIndex > this.items.length - 1) {
        newIndex = 0
      }
      this.highlightedItemIndex = newIndex
      this.text = this.items[newIndex]?.Key ?? ''
    },
    onMouseMove (index) {
      if (this.highlightedItemIndex !== index) {
        this.highlightedItemIndex = index
      }
    },
    onArrowClick () {
      this.arrowBtnClicked = true
      if (this.disabled) {
        return
      }
      this.$refs.dropdownInput && this.$refs.dropdownInput.focus()
      this.isDroppedDown = !this.isDroppedDown
      this.isDdlArrowBtnClicked = true
    },
    isBrowseSelected (selected) {
      return (
        selected && selected.Value === listSearchOptions.browse.Value
      )
    },
    isNewSelected (selected) {
      return selected && selected.Value === listSearchOptions.new.Value
    },
    isBlankSelected (selected) {
      return (
        selected && selected.Value === listSearchOptions.blank.Value
      )
    },
    isAllSelected (selected) {
      return selected && selected.Value === listSearchOptions.all.Value
    },
    showBrowser () {
      this.browserSearchInfo = this.lastSearchInfo
      if (this.browserSearchInfo === null) {
        let query = ''
        if (this.lastSelected !== null) {
          query = this.lastSelected.Key
        }
        this.browserSearchInfo = this.createSearchInfo(query)
      }
      this.showingBrowser = true
      this.isOverlapping = $('body').hasClass('modal-open')
      this.$nextTick(() => {
        this.$refs.listSearchBrowser.open()
      })
    },
    closeBrowser () {
      this.$nextTick(() => {
        this.showingBrowser = false
      })
    },
    selectFreeformEntry () {
      var existingItem = this.items.find(
        (x) => x.Key.toLowerCase() === this.text.toLowerCase()
      )
      if (existingItem) {
        this.selectItem(existingItem)
      } else {
        const newId = uuid.v4()
        this.descriptionList.push({ Key: this.text, Value: newId })
        this.addDescriptions()
        this.resetItems()
        this.highlightedItemIndex = this.items.length - 1
        const kvp = this.items[this.highlightedItemIndex]
        this.selectItem(kvp)
        this.setSelectedValue(newId)
        this.$emit('onNewDescriptionId', newId)
      }
    },
    getDescriptionsToAdd () {
      const result = []
      if (this.resultsList === undefined || this.resultsList === null || this.resultsList.length === 0) {
        return this.descriptionList
      }
      var testResults = this.resultsList[0]
      if (testResults === undefined || testResults === null || testResults.Value === undefined || testResults.Value === null) {
        return result
      }
      const ids = this.resultsList.map((x) => x.Value)
      if (ids && ids.length == 0) {
        return this.descriptionList
      }
      for (let di = 0; di < this.descriptionList.length; di++) {
        const desc = this.descriptionList[di]
        const idToCheck = desc.Value
        let addThis = false
        for (let i = 0; i < ids.length; i++) {
          if (ids[i] === idToCheck) {
            addThis = true
            break
          }
        }
        if (addThis) {
          result.push(desc)
        }
      }
      return result
    },
    addDescriptions () {
      if (this.descriptionList.length == 0) {
        return
      }
      const toAdd = this.getDescriptionsToAdd()
      if (this.resultsList === null && (this.text === '' || this.text.length <= 2)) {
        if (this.isDdlArrowBtnClicked || Event.type == 'blur') {
          this.resultsList = []
          this.isDroppedDown = false
          this.isDdlArrowBtnClicked = false
        }
      }
      if (this.resultsList !== null && this.resultsList.length === 0) {
        this.resultsList.push(...toAdd.sort((x) => x.Key))
      }
    },
    async loadDescriptions () {
      if (this.isDescriptionList) {
        this.descriptionList.length = 0
        const searchInfo = this.createSearchInfo('')
        this.descriptionList = await this.getSearchResults(searchInfo)
        if (this.descriptionList.length > 0) {
          this.addDescriptions()
          this.resetItems()
        }
      }
    },
    searchDescriptions (query) {
      if (query && query.length > 2) {
        const results = []
        for (let i = 0; i < this.descriptionList.length; i++) {
          var desc = this.descriptionList[i]
          var descKey = desc.Key.toLowerCase()
          if (descKey.includes(query.toLowerCase())) {
            results.push(desc)
          }
        }
        if (results && results.length > 0) {
          return results
        }
      }
      return []
    },
    searchDescriptionById (value) {
      const desc = this.descriptionList.filter((x) => x.Value === value)
      if (desc && desc.length > 0) {
        return desc[0]
      }
    },
    async doShowDropDownList (doShowDropdown, searchInfo) {
      if (doShowDropdown) {
        this.isDroppedDown = true
        this.$nextTick(() => {
          let currentIndex = -1
          for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i]
            if (
              item.Key.toLowerCase().startsWith(
                searchInfo.formattedInput.toLowerCase()
              )
            ) {
              currentIndex = i
              break
            }
          }
          this.highlightedItemIndex = currentIndex
        })
      }
    }
  },
  computed: {
    isDirty () {
      return this.getSelectedValue() != this.externalValue
    },
    selectedItemText () {
      const kvp = this.getSelectedValue()
      if (kvp === null) {
        return null
      } else {
        return kvp.Key
      }
    },
    selectedKvp () {
      return this.selectedItem
    },
    isTextSet () {
      return this.text !== null && this.text !== undefined && this.text !== ''
    },
    currentStockpointId () {
      if (this.stockpointId !== null && this.stockpointId.length > 0) {
        return this.stockpointId
      } else {
        return this.$store.getters.getStockpointId
      }
    },
    allowsFreeformEntry () {
      // Descriptions allow freeform entry of new items
      return this.isDescriptionList
    },
    isDescriptionList: {
      get () {
        const descriptionTypeIds = [5, 6, 20, 21, 22, 23, 24, 25]
        return descriptionTypeIds.includes(this.searchProvider.listTypeId)
      },
      set (descriptionTypeIds) {
        return descriptionTypeIds
      }
    },
    isCostCodeList () {
      const costCodeTypeIds = [4, 36]
      return costCodeTypeIds.includes(this.searchProvider.listTypeId)
    },
    isValidFreeformEntry () {
      return this.allowsFreeformEntry && this.isTextSet
    }
  },
  directives: {
    'click-outside': {
      bind (el, binding, vnode) {
        el.clickOutside = function (e) {
          if (!(el == e.target || el.contains(e.target))) {
            vnode.context[binding.expression](e)
          }
        }
        document.body.addEventListener('click', el.clickOutside)
      },
      unbind (el) {
        document.body.removeEventListener('click', el.clickOutside)
      }
    }
  }
}
</script>

<style scoped>
:root {
  --primary-color: #d29735;
}
.dropdown {
  position: relative;
  width: 100%;
}
.dropdown-input {
  width: 100% !important;
  padding: 10px 16px;
  border: 1px solid #e5e6e7;
  background: #fff;
  line-height: 1.5em;
  outline: none;
}
.dropdown-input:focus {
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder {
  opacity: 0.7;
}
.dropdown-input:disabled {
  background-color: #e9ecef;
}
.dropdown-list {
  z-index: 2051;
  position: absolute;
  width: 100%;
  max-height: 400px;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  overflow-y: scroll;
}
.dropdown-item {
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  padding: 6px 10px;
  cursor: pointer;
}
.dropdown-item-highlight {
  background: var(--primary-color) !important;
  color: #ffffff !important;
}
.dropdown-item:hover {
  background: inherit;
  color: inherit;
}
.dropdown-arrow {
  cursor: pointer;
  position: absolute;
  color: rgba(60, 60, 60, 0.5);
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
  font-size: 18px;
  font-weight: 900;
}
.default-font-Color {
  color: #000000;
}
.not-allowed:hover {
  cursor: not-allowed;
}
</style>
