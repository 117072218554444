const service = {
  requests: {
    title: 'Navegador de solicitud de servicio',
    equipmentTitle: 'Solicitud de servicio de equipo',
    actionHeader: {
      0: {
        title: 'Crear solicitud de servicio',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Convertir en orden de trabajo',
        icon: 'fas fa-file-export'
      },
      2: {
        title: 'Cerrar solicitud de servicio'
      },
      3: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip'
      }
    },
    tableHeader: {
      desktop: [
        {
          label: 'No.',
          key: 'number',
          sortable: 'true'
        },
        {
          label: 'Urgencia',
          key: 'priority',
          sortable: 'true'
        },
        {
          label: 'Solicitud',
          key: 'createdOn',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Estado',
          key: 'state',
          sortable: 'true'
        },
        {
          label: 'Estatus',
          key: 'status',
          sortable: 'true'
        },
        {
          label: 'Artículo No.',
          key: 'itemNumber',
          sortable: 'true'
        },
        {
          label: 'Descripción del Artículo',
          key: 'itemDescription',
          sortable: 'true'
        },
        {
          label: 'Número de serie',
          key: 'serialNumber',
          sortable: 'true'
        },
        {
          label: 'Servicio solicitado',
          key: 'description',
          sortable: 'true'
        },
        {
          label: 'Nota',
          key: 'note',
          sortable: 'true'
        },
        {
          label: 'Fabricante',
          key: 'manufacturer',
          sortable: 'true'
        },
        {
          label: 'Modelo',
          key: 'model',
          sortable: 'true'
        },
        {
          label: 'Solicitado por',
          key: 'requestedBy',
          sortable: 'true'
        },
        {
          label: '',
          key: ''
        }
      ],
      mobile: [
        {
          label: '',
          key: 'serviceRequestId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'No.',
              key: 'number',
              sortable: 'true'
            },
            {
              label: 'Urgencia',
              key: 'priority',
              sortable: 'true'
            },
            {
              label: 'Solicitud',
              key: 'createdOn',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Estado',
              key: 'state',
              sortable: 'true'
            },
            {
              label: 'Estatus',
              key: 'status',
              sortable: 'true'
            },
            {
              label: 'Artículo No.',
              key: 'itemNumber',
              sortable: 'true'
            },
            {
              label: 'Descripción del Artículo',
              key: 'itemDescription',
              sortable: 'true'
            },
            {
              label: 'Número de serie',
              key: 'serialNumber',
              sortable: 'true'
            },
            {
              label: 'Servicio solicitado',
              key: 'description',
              sortable: 'true'
            },
            {
              label: 'Nota',
              key: 'note',
              sortable: 'true'
            },
            {
              label: 'Fabricante',
              key: 'manufacturer',
              sortable: 'true'
            },
            {
              label: 'Modelo',
              key: 'model',
              sortable: 'true'
            },
            {
              label: 'Solicitado por',
              key: 'requestedBy',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    requiringBrowseTableHeader: {
      desktop: [
        {
          label: 'Categoría',
          key: 'category',
          sortable: 'true'
        },
        {
          label: 'Número',
          key: 'itemNumber',
          sortable: 'true'
        },
        {
          label: 'Código de barras',
          key: 'barcode',
          sortable: 'true'
        },
        {
          label: 'Número de serie',
          key: 'serialNumber',
          sortable: 'true'
        },
        {
          label: 'Equipo',
          key: 'kitNumber',
          sortable: 'true'
        },
        {
          label: 'T',
          key: 'itemType',
          sortable: 'true'
        },
        {
          label: 'Descripción',
          key: 'description',
          sortable: 'true'
        },
        {
          label: 'Fabricante',
          key: 'manufacturer',
          sortable: 'true'
        },
        {
          label: 'Modelo',
          key: 'model',
          sortable: 'true'
        },
        {
          label: 'Cant.',
          key: 'quantity',
          sortable: 'true'
        },
        {
          label: 'Uom',
          key: 'uom',
          sortable: 'true'
        },
        {
          label: 'Estado',
          key: 'itemStatus',
          sortable: 'true'
        },
        {
          label: 'UP',
          key: 'personalIssue',
          sortable: 'true'
        },
        {
          label: 'Asignación',
          key: 'assignedTo',
          sortable: 'true'
        },
        {
          label: 'Edad',
          key: 'ageDays',
          sortable: 'true'
        },
        {
          label: 'Dueño',
          key: 'owner',
          sortable: 'true'
        },
        {
          label: 'Gerente',
          key: 'manager',
          sortable: 'true'
        },
        {
          label: 'Departamento',
          key: 'department',
          sortable: 'true'
        },
        {
          label: 'Supervisor',
          key: 'supervisor',
          sortable: 'true'
        },
        {
          label: 'Clase de artículo',
          key: 'className',
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Categoría',
              key: 'category',
              sortable: 'true'
            },
            {
              label: 'Número',
              key: 'itemNumber',
              sortable: 'true'
            },
            {
              label: 'Código de barras',
              key: 'barcode',
              sortable: 'true'
            },
            {
              label: 'Número de serie',
              key: 'serialNumber',
              sortable: 'true'
            },
            {
              label: 'Equipo',
              key: 'kitNumber',
              sortable: 'true'
            },
            {
              label: 'T',
              key: 'itemType',
              sortable: 'true'
            },
            {
              label: 'Descripción',
              key: 'description',
              sortable: 'true'
            },
            {
              label: 'Fabricante',
              key: 'manufacturer',
              sortable: 'true'
            },
            {
              label: 'Modelo',
              key: 'model',
              sortable: 'true'
            },
            {
              label: 'Cant.',
              key: 'quantity',
              sortable: 'true'
            },
            {
              label: 'Uom',
              key: 'uom',
              sortable: 'true'
            },
            {
              label: 'Estado',
              key: 'itemStatus',
              sortable: 'true'
            },
            {
              label: 'UP',
              key: 'personalIssue',
              sortable: 'true'
            },
            {
              label: 'Asignación',
              key: 'assignedTo',
              sortable: 'true'
            },
            {
              label: 'Edad',
              key: 'ageDays',
              sortable: 'true'
            },
            {
              label: 'Dueño',
              key: 'owner',
              sortable: 'true'
            },
            {
              label: 'Gerente',
              key: 'manager',
              sortable: 'true'
            },
            {
              label: 'Departamento',
              key: 'department',
              sortable: 'true'
            },
            {
              label: 'Supervisor',
              key: 'supervisor',
              sortable: 'true'
            },
            {
              label: 'Clase de artículo',
              key: 'className',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    searchContainer: {
      open: 'Abierto',
      closed: 'Cerrado',
      all: 'Todo',
      status: 'Estado',
      requestedBy: 'Solicitado por',
      serviceRequested: 'Servicio solicitado',
      requestedDate: 'Fecha solicitada',
      to: 'Para'
    },
    serviceRequest: {
      urgency: [
        'Bajo',
        'Normal',
        'Alto'
      ],
      serviceRequest: 'Petición de servicio',
      requestedBy: 'Solicitado por:',
      requestedOn: 'Solicitado en:',
      status: 'Estado',
      itemRequiringService: 'Artículo que requiere servicio',
      serviceRequestOrProblem: 'Solicitud de servicio o problema',
      ugency: 'Urgencia',
      note: 'Nota',
      serviceRequestNumber: 'Numero de Servicio Solicitado:',
      browserServiceRequests: 'Examinar solicitudes de servicio',
      serviceDescription: 'Descripción del servicio'
    },
    toolBrowser: {
      title: 'Navegador de herramientas'
    },
    dlgMessages: {
      delete: '¿Está seguro de que desea eliminar la solicitud de servicio seleccionada?',
      addItem: 'Solo puede asociar exactamente un artículo con una solicitud de servicio.',
      cantDeleteSRMsg: 'No puede eliminar una solicitud de servicio una vez que se ha convertido en una orden de trabajo.'
    },
    dlgMessagesClose: {
      success: 'Solicitudes de servicio han sido cerradas.',
      close: '¿Está seguro de que desea cerrar las solicitudes de servicio?',
      allWorkOrderSelect: 'No puede cerrar las solicitudes de servicio una vez que se han convertido en una orden de trabajo. Actualice su selección.',
      workOrderWithserviceRequest: 'Hay solicitudes de servicio seleccionadas con órdenes de trabajo. No puede cerrar una solicitud de servicio que tiene una orden de trabajo. Actualice su selección.',
      closeServiceRequest: 'No puede cerrar solicitudes de servicio una vez que ya están cerradas. Actualice su selección.'
    },
    convertMsg: 'Esta solicitud de servicio ya se ha convertido.',
    browserLineActions: [
      {
        title: 'Vista',
        actionName: 'editarBoletodeinventario',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Cerca',
        actionName: 'closeserviceRequest',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Eliminar',
        actionName: 'deleteCountTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  workOrder: {
    title: 'Navegador de órdenes de trabajo',
    equipmentTitle: 'Explorador de órdenes de trabajo de equipos',
    subTitle: 'Orden de trabajo',
    filterTitle: 'Órdenes de trabajo del Navegador',
    actionHeader: {
      0: {
        title: 'Crear orden de trabajo',
        icon: 'fas fa-plus'
      }
    },
    subActionHeader: {
      0: {
        title: 'Guardar',
        icon: 'fas fa-save'
      },
      1: {
        title: 'Imprimir',
        icon: 'fas fa-print'
      },
      2: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip'
      },
      3: {
        title: 'Editar orden de trabajo',
        icon: 'fas fa-edit'
      }
    },
    subActionHeaderViewPermission: {
      0: {
        title: 'Imprimir',
        icon: 'fas fa-print'
      },
      1: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip'
      }
    },
    filter: {
      0: 'Solicitudes de Servicio',
      1: 'Fecha de creación',
      2: 'Para'
    },
    edit: {
      save: {
        title: {
          0: 'Actualizar registro de herramientas',
          1: '¿Cerrar orden de trabajo?'
        },
        labels: {
          0: 'Medidor',
          1: 'Medidor de corriente',
          2: 'Nuevo medidor',
          3: 'Diferencia',
          4: 'Estado',
          5: 'Estado actual',
          6: 'Nuevo estado',
          7: 'Número de artículo'
        },
        msg: {
          0: 'Al establecer la fecha de finalización en el pasado, esto puede causar la generación de una orden de trabajo vencida, si está utilizando un plan de servicio para este artículo. Estás seguro de que quieres continuar?',
          1: 'No se ha fijado la fecha ni la hora de finalización. Si se cierra, esta orden de trabajo no aparecerá en ningún informe que espere una fecha de finalización válida. Estás seguro de que quieres continuar?',
          2: 'Esta orden de trabajo no se ha guardado. ¿Desea Guardar la Orden de Trabajo para poder imprimirla?',
          3: 'fecha y hora requeridas'
        }
      },
      detail: {
        title: 'Detalles de orden de trabajo',
        serviceEventTitle: 'Resumen de eventos de servicio',
        summary: {
          title: 'Resumen de orden de trabajo',
          labels: {
            0: 'Servicio solicitado',
            1: 'Solicitar Motas',
            2: 'Número de PO',
            3: 'Ver solicitud de servicio...',
            4: 'Reaparición'
          }
        },
        schedule: {
          title: 'Calendario',
          labels: {
            0: 'Asignado a',
            1: 'Urgencia',
            2: 'Fecha de Vencimiento'
          }
        },
        completionDetails: {
          title: 'Detalles de finalización',
          labels: {
            0: 'Fecha de finalización',
            1: 'Medidor al finalizar',
            2: 'Completado por',
            3: 'Notas'
          }
        }
      },
      tasks: {
        title: 'Tareas',
        required: {
          title: 'Solicitado',
          labels: {
            0: 'Tareas de ordenes de trabajo',
            1: 'Tareas de eventos de servicio'
          }
        },
        history: {
          title: 'Historial',
          labels: {
            0: 'Trabajo realizado'
          }
        },
        detail: {
          label: {
            0: 'Detalles de tarea',
            1: 'Tarea No.',
            2: 'Tipo de tarea',
            3: 'Descripción',
            4: 'Tarea de calibración',
            5: 'Aprobar / Falla de Valor',
            6: 'Aprobar / Falla',
            7: 'Aprobar',
            8: 'Falla',
            9: 'Estándar',
            10: 'Unidad',
            11: 'Como encontrado',
            12: 'Como salido',
            13: 'Información de finalización',
            14: 'Realizado por',
            15: 'Fecha / Hora',
            16: 'Est. Hrs',
            17: 'Hrs. Actuales.',
            18: 'Notas',
            19: 'Cargos',
            20: 'Centro de costos',
            21: 'Código de costos',
            22: 'Clase de servicio'
          }
        },
        warning: {
          title: '¿Marcar cómo no completado?',
          msg: 'Esta tarea ha sido marcada como \'Finalizada\'. ¿Te gustaría marcar esta tarea como NO \'Completada\' y proceder?',
          addErrorMsg: 'Por favor agregue un ID de artículo válido en la orden de trabajo para agregar una tarea'
        }
      },
      instructions: {
        title: 'Instrucciones'
      },
      requiredTools: {
        title: 'Herramientas necesarias',
        addModels: {
          0: 'Herramientas necesarias',
          1: 'Herramientas necesarias para orden de trabajo',
          2: 'Navegador de modelos de herramientas'
        },
        warning: {
          title: 'Confirmar eliminación de herramienta'
        }
      },
      parts: {
        title: 'Partes',
        labels: {
          0: 'Partes y Suministros',
          1: 'Usar partes'
        },
        addParts: {
          0: 'Partes requeridas',
          1: 'Partes requeridas para orden de trabajo',
          2: 'Inventario de materiales',
          3: 'Usado',
          4: 'Cantidad actual',
          5: 'Solo repare las piezas de este activo',
          6: 'Todas'
        },
        warning: {
          msg: 'Por favor seleccione un artículo para reparar primero'
        }
      },
      charges: {
        title: 'Cargos',
        labels: {
          0: 'Cargos de orden de trabajo',
          1: 'Valores predeterminados de orden de trabajo',
          2: 'Centro de costos',
          3: 'Código de costos',
          4: 'Clase de Servicio',
          5: 'Total Hrs.',
          6: 'Total Partes $',
          7: 'Total Labor $',
          8: 'Total Otro $',
          9: 'Total Cargo $',
          10: 'Descripción de cargo',
          11: 'Cant.',
          12: 'Costo',
          13: 'Unidad',
          14: 'Cargo',
          15: 'Cargos adicionales',
          16: 'Cargos por eventos de servicio',
          17: 'Valores predeterminados de eventos de servicio'
        },
        warning: {
          title: 'Confirmar eliminación de cargo',
          msg: '¿Estás seguro de que quieres eliminar las filas seleccionadas? Click \'Si\' para eliminar o \'No\' para cancelar.'
        }
      },
      assignments: {
        title: 'Asignaciones',
        labels: {
          0: 'Historial de asignaciones',
          1: 'Reasignar este objeto...'
        },
        errorMsg: 'No puede realizar esta función hasta que se haya creado la orden de trabajo (guardada al menos una vez).'
      }
    }
  },
  schedule: {
    title: 'Navegar el programa de servicio',
    filterTitle: 'Servicio de programación',
    actionHeader: {
      0: {
        title: 'Imprimir',
        icon: 'fas fa-print'
      },
      1: {
        title: 'Exportar datos',
        icon: 'fas fa-file-export'
      }
    },
    lineAction: {
      0: {
        title: 'Ver orden de trabajo'
      },
      1: {
        title: 'Ver ítem'
      },
      2: {
        title: 'Explique'
      }
    },
    orderColumns: [
      {
        key: 'Asignado a',
        value: 'Assigned To'
      },
      {
        key: 'Código de barras',
        value: 'Barcode'
      },
      {
        key: 'Descripción',
        value: 'Description'
      },
      {
        key: 'Número de artículo',
        value: 'Item Number'
      },
      {
        key: 'Fabricante',
        value: 'Manufacturer'
      },
      {
        key: 'Modelo',
        value: 'Model'
      }
    ],
    filter: {
      0: 'Abierto',
      1: 'Programado',
      2: 'Todo',
      3: 'Proxima fecha',
      4: 'Para',
      5: 'Asignación actual',
      6: 'Número de artículo',
      7: 'Modelo',
      8: 'Orden de trabajo',
      9: 'Ordenar'
    },
    explanation: {
      title: 'Explicación del evento de servicio'
    }
  }
}

export default service
