<template>
  <thead class="freez-header">
    <tr>
      <template v-if="!isMobile">
        <template v-for="(header, index) in tableHeader.desktop">
        <th
          v-if="header.isModify === undefined ? true : header.isModify === isModify"
          :key="'ticket_header_desktop_' + index"
          class="tw-grid-header text-left"
          :class="getHeaderClass(index)"
          data-sort-ignore="false"
          :style="getHeaderStyle(header, index)"
        >
          <template v-if="header.type == 'check'">
            <div class="form-group-large" v-if="header.headerCheckboxVisible != false">
              <input
                type="checkbox"
                :id="'check_all_items_' + header.key"
                :checked="isCheckedAll"
                @click="baseEvents.onSelectAll()">
              <label
                :for="'check_all_items_' + header.key"
              ></label>
            </div>
          </template>
          <template v-else-if="header.isSelectAllWithKey === true">
            <div class="form-group-large">
                <input
                  type="checkbox"
                  :id="'check_all_items'"
                  :checked="isCheckedAllWithKey"
                  @click="baseEvents.onSelectAllWithKey(header.key)">
                  <label
                    :for="'check_all_items'"
                ></label>
                <span class="text-center mr-2" v-html="header.label"></span>
            </div>
          </template>
          <template v-else>
            <span class="text-center" @click="onSort(header, index)" v-html="header.label"></span>
            <span @click="onSort(header, index)" :class="!header.sortable ? '' : 'footable-sort-indicator'"></span>
          </template>
        </th>
        </template>
      </template>
      <template v-else>
        <template v-for="(header, index) in tableHeader.mobile">
          <th
            v-if="header.isModify === undefined ? true : header.isModify === isModify"
            :key="'ticket_header_mobile_' + index"
            class="footable-visible footable-sortable tw-grid-header"
            :style="getHeaderStyle(header, index)"
          >
            <template v-if="header.type == 'check'">
              <div class="form-group-large">
                <input
                  type="checkbox"
                  :id="'check_all_items_mobile_' + header.key"
                  :checked="isCheckedAll"
                  @click="baseEvents.onSelectAll()">
                <label
                  :for="'check_all_items_mobile_' + header.key"
                ></label>
              </div>
            </template>
            <template v-else>
              <span class="text-center" v-html="header.label"></span>
            </template>
          </th>
        </template>
      </template>
    </tr>
    <tr v-if="showFilters && !isMobile" style="background-color:#F0F1F2">
      <template
        v-for="(header, index) in tableHeader.desktop"
      >
        <td
          v-if="header.isModify === undefined ? true : header.isModify === isModify"
          :key="`transfer_list_header_${index}_` + header.key">
            <input class="form-control"
              v-if="header.type != Boolean &&
                header.type != 'check' &&
                header.type != 'InKit' &&
                header.type != 'BleTag' &&
                header.label != 'IsTwUser' &&
                header.label != '' &&
                header.showColFilter != false"
              type="text"
              v-model="tableFilter[header.key]"
              v-on:keyup = "filterData()"
            />
        </td>
      </template>
    </tr>
  </thead>
</template>
<script>
import $ from 'jquery'
import { useMediaQueryMatch } from '../../composables/useMediaQueryMatch'
import { useTWGridBase } from '../../composables/gridBase'

export default {
  name: 'TWGridHeader',
  props: {
    tableHeader: {
      type: Object,
      required: true
    },
    isCheckedAll: {
      type: Boolean,
      default: false
    },
    isCheckedAllWithKey: {
      type: Boolean,
      default: false
    },
    isSortable: {
      type: Array,
      required: true
    },
    isModify: {
      type: Boolean,
      required: false,
      default: false
    },
    showFilters: {
      type: Boolean,
      required: true
    },
    tableFilter: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      userGridPrefrences: [],
      timer: 0
    }
  },
  mounted () {
    this.setupGridResizer()
  },
  setup () {
    // map the isMatch result to a new variable called isMobile
    const { isMatch: isMobile } = useMediaQueryMatch()
    const baseEvents = useTWGridBase()
    // return isMobile so it's available in the template and the other code
    return { isMobile, baseEvents }
  },
  methods: {
    onSort (header, index) {
      if (!header.sortable) return
      this.$emit('onSort', { key: header.key, type: header.type, index })
    },
    getHeaderStyle (header, index) {
      // Mobile table requires an unset width for the last column to
      // respect the other column widths
      if (this.isMobile && index === this.tableHeader.mobile.length - 1) {
        return {}
      }
      const colWidth = this.getColumnWidth(header.label?.trim())
      if (colWidth) {
        return { width: colWidth }
      } else if (header.width) {
        return { width: header.width }
      }
      if (header.type && (header.type === 'check' || header.type === 'InKit')) {
        return { width: colWidth ?? '45px' }
      }

      if (['itemType', 'personalIssue', 'ageDays', 'quantity'].includes(header.key)) {
        return { width: colWidth ?? '75px' }
      }

      if (['lastScanLocation', 'lastScanArea'].includes(header.key)) {
        return { width: colWidth ?? '200px' }
      }

      return { width: colWidth ?? '150px' }
    },
    getHeaderClass (index) {
      if (index < this.isSortable.length) {
        if (this.isSortable[index].sorted) {
          return this.isSortable[index].asc ? 'footable-sorted' : 'footable-sorted-desc'
        }
      }
      return ''
    },
    setupGridResizer () {
      let thElm
      let startOffset
      const table = document.getElementsByClassName('footable')[0]
      Array.prototype.forEach.call(
        document.querySelectorAll('.tw-grid-header'),
        function (th) {
          th.style.position = 'relative'
          const thText = th.innerText
          const grip = document.createElement('div')
          if (thText != null && thText != '') {
            grip.innerHTML = '&nbsp;'
            grip.classList.add('resizer')
            grip.style.height = `${table.offsetHeight}px`
            th.classList.add('resizerHeader')
            grip.addEventListener('mousedown', function (e) {
              thElm = th
              startOffset = th.offsetWidth - e.pageX
              grip.classList.add('resizing')
            })
            th.appendChild(grip)
          }
        })
      document.addEventListener('mousemove', function (e) {
        if (thElm) {
          thElm.style.width = startOffset + e.pageX + 'px'
        }
      })
      document.addEventListener('mouseup', (e) => {
        if (thElm) {
          this.saveGridResizePrefrence(thElm.innerText, thElm.style.width)
        }
        Array.prototype.forEach.call(
          document.querySelectorAll('.resizer'),
          function (div) {
            div.classList.remove('resizing')
          })
        thElm = undefined
      })
      $('.table-nowraptext .tw-grid-row td').bind('mouseenter', function () {
        var $this = $(this)
        if (this.offsetWidth < this.scrollWidth && !$this.attr('title')) {
          $this.attr('title', $this.text())
        }
      })
    },
    saveGridResizePrefrence (colName, colWidth) {
      colName = colName.trim()
      const userId = this.userAccountId
      const pageName = this.pageName
      const orgId = this.orgId
      let userGridSettings = []
      const GridSettings = localStorage.getItem('GridSettings')
      if (GridSettings !== null && GridSettings.length > 0) {
        userGridSettings = JSON.parse(GridSettings)
        const userGridData = userGridSettings.filter(function (item) {
          return (item.userId === userId && item.pageName === pageName &&
           item.orgId === orgId)
        })
        if (userGridData.length > 0) {
          const found = userGridData[0].cols.some(obj => {
            if (Object.values(obj)[0].trim() === colName) {
              obj.value = colWidth
              return true
            }
          })
          if (!found) {
            userGridData[0].cols.push({ key: colName, value: colWidth })
          }
        } else {
          this.updateGriColumns(
            userGridSettings,
            colName,
            colWidth,
            userId,
            pageName,
            orgId)
        }
      } else {
        this.updateGriColumns(
          userGridSettings,
          colName,
          colWidth,
          userId,
          pageName,
          orgId)
      }
      localStorage.setItem('GridSettings', JSON.stringify(userGridSettings))
    },
    updateGriColumns (userGridSettings, colName, colWidth, userId, pageName, orgId) {
      const GridCols = []
      GridCols.push({ key: colName, value: colWidth })
      userGridSettings.push({
        userId: userId,
        pageName: pageName,
        orgId: orgId,
        cols: GridCols
      })
    },
    getColumnWidth (colName) {
      let colWidth = null
      try {
        const GridSettings = localStorage.getItem('GridSettings')
        const userId = this.userAccountId
        const pageName = this.pageName
        const orgId = this.orgId
        if (GridSettings != null) {
          const userGridSettings = JSON.parse(GridSettings)
          const userGridData = userGridSettings.filter(function (item) {
            return (item.userId === userId && item.pageName === pageName &&
             item.orgId === orgId)
          })
          if (userGridData.length > 0) {
            const found = userGridData[0].cols.some(obj => {
              if (Object.values(obj)[0].trim() === colName) {
                colWidth = obj.value
                return true
              }
            })
          }
        }
      } catch (ex) {
        colWidth = null
      }
      return colWidth
    },
    filterData () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        const obj = { tableFilter: this.tableFilter, mergedHeader: this.tableHeader.desktop }
        this.$emit('onGridHeaderDataFilter', obj)
      }, 500)
    }
  },
  computed: {
    userAccountId () {
      return localStorage.getItem('UserId')
    },
    pageName () {
      const pageUrl = this.$route.path.split('/')
      const pageUrlArr = pageUrl.filter(function (el) {
        return el != ''
      })
      return pageUrlArr[pageUrlArr.length - 1]
    },
    orgId () {
      return localStorage.getItem('OrgId')
    },
    userGridSettings () {
      return localStorage.getItem('GridSettings')
    }
  }
}
</script>
<style scoped>
.tw-grid-header {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    background: #4d565f;
    padding: 10px 15px;
    white-space: nowrap;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    min-width: 45px;
    text-align: center;
    vertical-align: middle;
}
</style>
<style>
.resizer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}
.resizer:hover,
.resizing {
    border-right: 3px solid rgb(219, 213, 213);
    width: 3px;
}
.resizerHeader {
     border-right: 2px solid rgb(219, 213, 213);
}
.freez-header{
  position: sticky; top: 0; z-index: 98;
}
tr th:first-child:has(input[type=checkbox]),tr td:first-child:has(input[type=checkbox]){
  position: sticky!important;
  left: 0;
  z-index: 50;
}
</style>
