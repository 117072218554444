const platformPermissionsTooltip = {
  TW: {
    Inventory: {
      Catalog: [
        'Acceso/Ver: capacidad de acceder y utilizar las funcionalidades del menú del catálogo'
      ],
      ToolModel: [
        'Acceso/Ver: acceso para ver cualquier registro de modelo de herramienta en el catálogo',
        'Agregar/Editar: capacidad de crear nuevos registros de modelo de herramienta y editar campos en el registro de modelo de herramienta del artículo',
        'Eliminar: capacidad de eliminar un registro de modelo de herramienta'
      ],
      MaterialModel: [
        'Acceso/Ver: acceso para ver cualquier registro de modelo de material en el catálogo',
        'Agregar/Editar: capacidad de crear nuevos registros de modelo de material y editar campos en el registro de modelo de material del artículo',
        'Eliminar: capacidad de eliminar un registro de modelo de material'
      ],
      Components: [
        'Acceso/Ver: capacidad de ver los componentes asociados en todos los registros de herramientas del modelo de herramienta',
        'Agregar/Editar: capacidad de agregar y editar herramientas asociadas que está rastreando en la plataforma con este registro de modelo',
        'Agregar/Editar: capacidad de eliminar componentes del registro del modelo de una herramienta, sin eliminar los registros del modelo de herramienta'
      ],
      DataSource: [
        'Acceso/Ver: capacidad de ver los modelos de herramientas que están almacenados en la fuente de datos en línea',
        'Agregar/Editar: capacidad de importar modelos de herramientas desde la fuente de datos en línea a la de su empresa'
      ],
      RepairParts: [
        'Acceso/Ver: capacidad de ver las piezas de reparación que se han asociado con un registro de modelo específico',
        'Agregar/Editar: capacidad de agregar, editar la cantidad requerida y asociar cualquiera de sus materiales y consumibles como piezas de reparación para un registro de modelo específico',
        'Eliminar: capacidad de eliminar cualquier material y consumible de la pestaña de piezas de reparación en un modelo específico',
        'Realizar: capacidad de agregar piezas de reparación en servicio > piezas de reparación en la pantalla de detalles del producto'
      ],
      ItemBrowser: [
        'Acceso/Ver: capacidad de acceder y utilizar las funcionalidades del menú del navegador de elementos'
      ],
      ToolDetail: [
        'Acceso/Ver: acceso para ver cualquier registro de herramienta en el navegador de elementos',
        'Agregar/Editar: capacidad de crear nuevos registros de herramientas, agregar inventario y editar campos en el registro de herramientas del artículo',
        'Eliminar: capacidad de eliminar un registro de herramienta'
      ],
      MaterialDetail: [
        'Acceso/Ver: acceso para ver cualquier registro de material en el navegador de artículos',
        'Agregar/Editar: capacidad de crear nuevos registros de materiales, agregar inventario y editar campos en el registro de materiales del artículo',
        'Eliminar: capacidad de eliminar un registro de material'
      ],
      AdjustInventory: [
        'Realizar: capacidad de ajustar la cantidad de herramientas presentes en una tarea particular'
      ],
      Kits: [
        'Acceso/Ver: capacidad de ver el contenido de un kit en el navegador de artículos',
        'Agregar/Editar: capacidad de crear nuevos kits y cambiar el contenido de un kit en el navegador de artículos',
        'Eliminar: posibilidad de eliminar kits'
      ],
      RetireTool: [
        'Realizar: capacidad de retirar un activo y eliminarlo del explorador de elementos a la página Administrar herramientas retiradas'
      ],
      StockpointSpecific: [],
      LowLevelBrowser: [
        'Acceso/Ver: capacidad de ver herramientas y materiales que se encuentran en el navegador de bajo nivel'
      ],
      ManageRetiredTools: [
        'Acceso/Ver: capacidad de explorar, buscar y ver herramientas retiradas'
      ]
    },
    InventoryMovement: {
      PickTicket: [
        'Acceso/Ver: capacidad de ver tickets de recogida abiertos o cerrados',
        'Agregar/Editar: capacidad de crear, abrir y editar tickets de selección existentes en estado de borrador',
        'Eliminar: posibilidad de eliminar un ticket de recogida abierto',
        'Realizar: capacidad de recoger, cerrar y transferir artículos seleccionados desde un ticket de recogida'
      ],
      Transfers: [
        'Acceso/Ver: capacidad de buscar y ver boletos de transferencia cerrados',
        'Eliminar: posibilidad de eliminar tickets de transferencia cerrados',
        'Realizar: capacidad de crear un boleto de transferencia y capacidad de usar el botón de proceso de transferencia en un boleto de transferencia abierto'
      ],
      CountInventory: [
        'Acceso/Ver: capacidad de ver los ajustes del inventario y explorar o buscar tickets de conteo abiertos o cerrados',
        'Agregar/Editar: capacidad de crear, abrir y editar tickets de conteo existentes',
        'Eliminar: posibilidad de eliminar un ticket de conteo existente',
        'Realizar: capacidad de contar artículos en un ticket de conteo y capacidad de publicar ajustes en el inventario'
      ],
      RequisitionsandReturns: [
        'Acceso/Ver: capacidad de acceder a la página del menú de solicitudes y devoluciones',
        'Agregar/Editar: capacidad de crear, abrir y editar solicitudes y devoluciones existentes',
        'Eliminar: capacidad de eliminar una solicitud abierta o una devolución'
      ],
      ShowModelRates: [
        'Acceso/Ver: capacidad de acceder a las tarifas de solicitudes'
      ],
      ShowModelQuantities: [
        'Acceso/Ver: capacidad de acceder a las cantidades de stock de solicitudes'
      ],
      RequisitionBrowser: [
        'Acceso/Ver: capacidad de ver el navegador de solicitudes y las solicitudes en el navegador. Agregar/Editar: Capacidad para editar solicitudes. Eliminar: Posibilidad de eliminar Solicitudes.'
      ]
    },
    'Service&Calibration': {
      ServiceBrowser: [
        'Acceso/Ver: capacidad de acceder y utilizar las funcionalidades del menú del navegador de servicios'
      ],
      ServiceLog: [
        'Acceso/Ver: capacidad de ver solicitudes de servicio abiertas o cerradas',
        'Agregar/Editar: capacidad de crear y editar solicitudes de servicio existentes en estado pendiente',
        'Eliminar: capacidad de eliminar una solicitud de servicio abierta',
        'Realizar: capacidad de convertir una solicitud de servicio en una orden de trabajo'
      ],
      ServiceEvent: [
        'Acceso/Ver: capacidad de ver eventos de servicio existentes',
        'Agregar/Editar: capacidad de crear y editar eventos de servicio existentes',
        'Eliminar: capacidad de eliminar eventos de servicio',
        'Realizar: capacidad de guardar un nuevo evento de servicio y capacidad de agregar un evento desde el modelo'
      ],
      ServiceTicket: [
        'Acceso/Ver: capacidad de ver órdenes de trabajo abiertas o cerradas',
        'Agregar/Editar: capacidad de crear nuevas y editar órdenes de trabajo existentes',
        'Eliminar: capacidad de eliminar órdenes de trabajo',
        'Realizar: capacidad de guardar y cerrar una orden de trabajo'
      ],
      ServiceSchedule: [
        'Acceso/Ver: capacidad de ver eventos abiertos o programados',
        'Agregar/Editar: capacidad de crear programas de servicio nuevos y editar los existentes',
        'Eliminar: capacidad de eliminar horarios de servicio',
        'Realizar: capacidad de guardar y cerrar horarios de servicio'
      ]
    },
    Purchasing: {
      PurchaseOrders: [
        'Acceso/Ver: capacidad de ver órdenes de compra abiertas o cerradas',
        'Agregar/Editar: capacidad de crear órdenes de compra nuevas y editar las existentes en estado de borrador',
        'Eliminar: capacidad de eliminar órdenes de compra abiertas o cerradas',
        'Realizar: capacidad de utilizar el botón de recibir orden de compra'
      ],
      POTicket: [
        'Acceso/Ver: capacidad de abrir órdenes de compra para ver sus detalles',
        'Agregar/Editar: capacidad de crear nuevas órdenes de compra en estado de borrador',
        'Eliminar: posibilidad de eliminar órdenes de compra',
        'Realizar: capacidad de guardar una orden de compra'
      ],
      ProductVendors: [],
      PricingPlans: [],
      ReceivingTickets: [
        'Acceso/Ver: capacidad de ver tickets de recepción cerrados'
      ],
      ReceivingTicket: [
        'Acceso/Ver: capacidad de ver un ticket de recepción abierto o cerrado y la capacidad de abrir la orden de compra, asociada con el ticket de recepción',
        'Agregar/Editar: capacidad de recibir contra una orden de compra, crear un nuevo ticket de recepción y editar un ticket de recepción abierto',
        'Realizar: capacidad de recibir artículos en su inventario'
      ]
    },
    'JobCost&Billing': {
      'Billing&InvoiceBrowser': [
        'Acceso/Ver: capacidad de acceder y utilizar las funciones del menú de facturación y costos del trabajo'
      ],
      BillingBrowser: [
        'Acceso/Ver: capacidad de abrir y ver la página del navegador de facturación'
      ],
      'CalculateJobCost&Billing': [
        'Realizar: capacidad de utilizar el botón calcular para calcular cargos no publicados'
      ],
      'MiscCharge/Credit': [
        'Acceso/Ver: capacidad de ver cargos o créditos diversos no publicados existentes',
        'Agregar/Editar: capacidad de crear nuevos cargos o créditos diversos no publicados y editar los existentes',
        'Eliminar: posibilidad de eliminar cargos o créditos varios existentes no contabilizados'
      ],
      'PostJobCost&Billing': [
        'Realizar: capacidad de publicar cargos existentes no publicados'
      ],
      RateSheets: [
        'Acceso/Ver: capacidad de ver materiales y consumibles existentes y hojas de tarifas de herramientas y equipos',
        'Agregar/Editar: capacidad de crear hojas de tarifas de materiales y consumibles, herramientas y equipos nuevos y editar los existentes',
        'Eliminar: capacidad de eliminar materiales y consumibles existentes y hojas de tarifas de herramientas y equipos'
      ],
      CompanyCalendar: [
        'Acceso/Ver: capacidad de abrir y ver la página del calendario de la empresa',
        'Agregar/Editar: posibilidad de editar el calendario de la empresa'
      ],
      'Calendar-CostCenter': [
        'Acceso/Ver: capacidad de abrir y ver la página del calendario de la empresa',
        'Agregar/Editar: posibilidad de editar el calendario de la empresa'
      ],
      HourlyUsageLog: [
        'Acceso/Ver: capacidad de abrir y ver el registro de uso del equipo',
        'Agregar/Editar: capacidad de agregar herramientas y equipos al registro de uso del equipo y editar líneas en el registro de uso del equipo',
        'Eliminar: capacidad de eliminar herramientas y equipos del registro de uso del equipo'
      ],
      'ExportJobCost&Billing': [
        'Realizar: capacidad de utilizar el botón exportar datos en el módulo de facturación y costos del trabajo'
      ]
    },
    Scheduling: {
      SchedulingRate: [
        'Acceso/Ver: la tarifa de un modelo al solicitar programación'
      ],
      SchedulingAvailability: [
        'Acceso/Ver: la disponibilidad de un modelo al solicitar programación'
      ],
      SchedulingBrowser: [
        'Acceso/Ver: la tarifa de un modelo al solicitar programación'
      ],
      SchedulingCalendar: [
        'Acceso/Ver: la disponibilidad de un modelo al solicitar programación'
      ],
      SchedulingRequest: [
        'Acceso/Ver: la disponibilidad de un modelo al solicitar programación'
      ]
    },
    'Reports&Graphs': {
      Reports: [
        'Acceso/Ver: acceso para generar/descargar varios informes y aplicar filtros para capturar datos de herramientas únicos'
      ],
      ToolReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de herramientas'
      ],
      ListReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de lista'
      ],
      AssignmentReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de transferencia'
      ],
      BarCodeLabels: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para los informes de etiquetas de códigos de barras'
      ],
      ConsumableReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de consumibles'
      ],
      PurchasingReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de compras'
      ],
      ServiceReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de servicio'
      ],
      BillingReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de facturación'
      ],
      TasksReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de Tareas'
      ],
      FuelReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de combustible'
      ],
      GraphReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes gráficos'
      ],
      CustomReports: [
        'Acceso/Ver: capacidad de ver y generar informes de lista personalizados',
        'Eliminar: capacidad de eliminar informes de listas personalizadas existentes'
      ],
      ScheduledReports: [
        'Acceso/Ver: capacidad de ver el menú de la página de informes programados',
        'Agregar/Editar: capacidad de agregar informes programados nuevos y editar los existentes para usuarios asignados con una frecuencia de correo electrónico determinada para informes específicos',
        'Eliminar: capacidad de eliminar informes programados existentes'
      ],
      CustomDashboards: []
    },
    Tracking: {
      LocationMap: [
        'Acceso/Ver: capacidad de ver el mapa de ubicación'
      ],
      FuelCards: [
        'Acceso/Ver: capacidad de acceder a las tarjetas de combustible desde el menú de navegación'
      ],
      WirelessGateways: [
        'Acceso/Ver: capacidad de acceder y ver puertas de enlace inalámbricas',
        'Agregar/Editar: capacidad de agregar puertas de enlace inalámbricas nuevas o editar las existentes',
        'Eliminar: posibilidad de eliminar puertas de enlace inalámbricas'
      ],
      WirelessTags: [
        'Acceso/Ver: capacidad de acceder y ver etiquetas inalámbricas',
        'Agregar/Editar: capacidad de agregar etiquetas inalámbricas nuevas o editar las existentes',
        'Eliminar: posibilidad de eliminar etiquetas inalámbricas'
      ]
    },
    ToolWatchSettings: {
      Categories: [
        'Acceso/Ver: acceso para ver las categorías de herramientas, materiales o plantillas existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar categorías de herramientas, materiales o plantillas',
        'Eliminar: posibilidad de eliminar categorías de herramientas, materiales o plantillas'
      ],
      Classes: [
        'Acceso/Ver: acceso para ver las clases existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar clases',
        'Eliminar: posibilidad de eliminar clases'
      ],
      StatusCodes: [
        'Acceso/Ver: acceda para ver los códigos de estado existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar códigos de estado',
        'Eliminar: capacidad de eliminar códigos de estado'
      ],
      ShippingMethods: [
        'Acceso/Ver: acceda para ver los métodos de envío existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar métodos de envío',
        'Eliminar: posibilidad de eliminar métodos de envío'
      ],
      UnitsofMeasure: [
        'Acceso/Ver: acceso para ver las unidades de medida existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar unidades de medida',
        'Eliminar: capacidad de eliminar unidades de medida'
      ],
      Vendors: [
        'Acceso/Ver: acceso para ver los proveedores existentes en la página de lista de configuración',
        'Agregar/Editar: capacidad de crear y administrar proveedores',
        'Eliminar: capacidad de eliminar proveedores'
      ],
      CostCenters: [
        'Acceso/Ver: acceda para ver los centros de costos existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar centros de costos',
        'Eliminar: posibilidad de eliminar centros de costos'
      ],
      CostCodes: [
        'Acceso/Ver: acceda para ver los códigos de costos existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar códigos de costos',
        'Eliminar: capacidad de eliminar códigos de costo'
      ],
      DataMergeUtility: [
        'Realizar: capacidad de fusionar datos que están disponibles en la ventana Fusión de datos'
      ]
    }
  },
  Safety: {
    Inspections: {
      InspectionMaintenance: [
        'Acceso/Ver: acceso a registros de inspección completados y en progreso'
      ],
      JobBankMaintenance: [
        'Acceso/Ver: acceso a registros de inspección de trabajo de la bolsa de trabajo'
      ],
      StandardChecklistMaintenance: [
        'Acceso/Ver: acceso a listas de verificación, categorías y elementos estándar de EHS'
      ],
      CustomChecklistMaintenance: [
        'Acceso/Ver: acceso a listas de verificación, categorías y elementos personalizados'
      ],
      CorrectiveActionTracking: [
        'Acceso/Ver: acceso a los detalles del elemento de acción correctiva para todas las inspecciones'
      ],
      RemoteSessions: [
        'Acceso/Ver: acceso a registros de inspección remota de VSR'
      ],
      InspectionSettings: [
        'Acceso/Ver: acceso a configuraciones personalizadas definidas por el usuario y administración de cartas de presentación'
      ]
    },
    Training: {
      TrainingEvents: [
        'Acceso/Ver: acceso para crear y administrar registros de capacitación programados, en curso y completados'
      ],
      TrainingTracking: [
        'Acceso/Ver: acceso para gestionar las capacitaciones requeridas y el seguimiento de los usuarios asignados y su última fecha de capacitación'
      ],
      ToolBoxTalks: [
        'Acceso/Ver: acceso al lugar donde se cargan las charlas de herramientas personalizadas'
      ],
      TrainingHistory: [
        'Acceso/Ver: acceso para ver el historial completo de registros de capacitación programados, en curso y completados'
      ],
      RequiredTrainings: [
        'Acceso/Ver: acceso para administrar los registros de capacitación requeridos'
      ],
      TrainingExam: [
        'Acceso/Ver: acceso para crear y administrar exámenes de capacitación de Toolboxtalk'
      ],
      TrainingConfirmation: [
        'Acceso/Ver: acceso para crear y administrar registros de eventos de confirmación de capacitación'
      ],
      TrainingSettings: [
        'Acceso/Ver: acceso a la configuración de permisos del módulo de capacitación'
      ]
    },
    Incidents: {
      IncidentMaintenance: [
        'Acceso/Ver: acceso para gestionar registros de incidentes, realizar investigaciones de incidentes y crear elementos de acción correctiva'
      ],
      OSHA301: [
        'Acceso/Ver: acceso para crear y administrar registros OSHA 301 desde cero o registros de incidentes existentes'
      ],
      OSHA300Log: [
        'Acceso/Ver: acceso para crear, administrar y producir registros OSHA 300 de la empresa y los registrables OSHA 301 incluidos'
      ],
      BehaviorMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de comportamiento de incidentes que son visibles para los usuarios finales del módulo'
      ],
      ConditionsMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de condición de incidente que son visibles para los usuarios finales del módulo'
      ],
      EntityMaintenance: [
        'Acceso/Ver: acceso para crear y administrar entidades de la empresa vinculadas a los registros OSHA 300'
      ],
      IncidentSettings: [
        'Acceso/Ver: acceso al módulo de incidentes y configuración de permisos de administrador'
      ]
    },
    Observations: {
      ObservationMaintenance: [
        'Acceso/Ver: acceso para gestionar registros de observación y elementos de acción correctiva de la parte responsable'
      ],
      CategoryMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de categorías de observación que son visibles para los usuarios finales del módulo'
      ],
      ContributingFactorMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de factores contribuyentes de observación que son visibles para los usuarios finales del módulo'
      ],
      ObservationSettings: [
        'Acceso/Ver: acceso al módulo de observación y configuración de permisos de administrador'
      ]
    },
    JobSafetyAnalysis: {
      JSAMaintenance: [
        'Acceso/Ver: acceso para crear y administrar formularios de Análisis de seguridad laboral, con sus tareas, peligros y controles únicos'
      ],
      JSABankMaintenance: [
        'Acceso/Ver: acceso para crear y administrar plantillas de formularios de JSA Bank, con sus tareas, riesgos y controles únicos para que los usuarios accedan en el campo'
      ],
      HazardsMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de peligros de JSA que son visibles para los usuarios finales del módulo'
      ],
      ControlsMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de control JSA que son visibles para los usuarios finales del módulo'
      ],
      ClassificationMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de clasificación JSA que son visibles para los usuarios finales del módulo'
      ],
      PPEMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de PPE de JSA que son visibles para los usuarios finales del módulo'
      ],
      RequiredTrainingMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de capacitación requeridas por JSA que son visibles para los usuarios finales del módulo'
      ],
      ChemicalMaintenance: [
        'Acceso/Ver: acceso para crear y administrar opciones de peligros de JSA que son visibles para los usuarios finales del módulo'
      ],
      JSASettings: [
        'Acceso/Ver: acceso a la configuración de permisos del módulo JSA'
      ]
    },
    SafetyScan: {
      Assets: [
        'Acceso/Ver: acceso para crear y administrar activos de escaneo de seguridad que están disponibles para que los usuarios finales del módulo los inspeccionen'
      ],
      AssetTypes: [
        'Acceso/Ver: acceso para crear y administrar tipos de activos que agrupan activos y se asignan a listas de verificación'
      ],
      Checklists: [
        'Acceso/Ver: acceso para crear y administrar preguntas de la lista de verificación para un tipo de activo específico que están disponibles para los usuarios finales del módulo'
      ],
      GenerateQRCodes: [
        'Acceso/Ver: acceso para crear y administrar códigos QR únicos para todos los activos. Produzca hojas de códigos QR y determine el tamaño y la marca del código QR'
      ],
      Overview: [],
      ScanCorrectiveActionTracking: [
        'Acceso/Ver: acceso a los detalles de los elementos de acción correctiva para todas las inspecciones de activos'
      ],
      Routes: [
        'Acceso/Ver: acceso para crear y administrar rutas que determinan el orden para inspeccionar múltiples activos'
      ],
      Locations: [],
      'Building,Floor&Area': [
        'Acceso/Ver: acceso para crear y administrar información detallada de dónde se coloca cada activo individual'
      ],
      Zone: [
        'Acceso/Ver: acceso para crear y gestionar zonas de equipos vinculadas a cada activo y ruta'
      ],
      Division: [
        'Acceso/Ver: acceso para crear y administrar divisiones de equipos vinculadas a cada activo'
      ],
      SafetyScanSettings: [
        'Acceso/Ver: acceso a la configuración de permisos del módulo Safety Scan'
      ]
    },
    Forms: {
      FormsMaintenance: [
        'Acceso/Ver: acceso para crear y administrar listas de verificación de formularios y preguntas que los usuarios del formulario pueden completar en el campo'
      ],
      CompletedForms: [
        'Acceso/Ver: acceso para ver y descargar todos los formularios completados por los usuarios finales del módulo'
      ],
      CorrectiveAction: [
        'Acceso/Ver: acceso para ver y descargar todo el seguimiento de acciones correctivas por parte de los usuarios finales del módulo.'
      ],
      FormsSettings: [
        'Acceso/Ver: acceso a la configuración de permisos del módulo de Formularios'
      ]
    },
    'EHSReports&Graphs': {
      PerformanceReports: [
        'Acceso/Ver: acceda para generar/descargar informes de Excel y aplicar filtros para capturar datos de seguridad únicos'
      ],
      InspectionPerformanceReports: [
        'Acceso/Ver: acceda para generar/descargar varios informes del módulo de inspección y aplicar filtros para capturar datos de inspección únicos'
      ],
      TrainingPerformanceReports: [
        'Acceso/Ver: acceda para generar/descargar varios informes de módulos de capacitación y aplicar filtros para capturar datos de capacitación únicos'
      ],
      IncidentsPerformanceReports: [
        'Acceso/Ver: acceso para generar/descargar varios informes de módulos de incidentes y aplicar filtros para capturar datos de incidentes únicos'
      ],
      ObservationsPerformanceReports: [
        'Acceso/Ver: acceda para generar/descargar varios informes del módulo de observación y aplicar filtros para capturar datos de observación únicos'
      ],
      JSAPerformanceReports: [
        'Acceso/Ver: acceda para generar/descargar varios informes del módulo JSA y aplicar filtros para capturar datos JSA únicos'
      ],
      SafetyScanPerformanceReports: [
        'Acceso/Ver: acceda para generar/descargar varios informes del módulo de escaneo de seguridad y aplicar filtros para capturar datos únicos de escaneo de seguridad'
      ],
      FormsPerformanceReports: [
        'Acceso/Ver: acceso para generar/descargar varios informes de módulos de formularios y aplicar filtros para capturar datos de formularios únicos'
      ],
      EHSScheduledReports: [
        'Acceso/Ver: acceso para crear y administrar informes de correo electrónico programados para destinatarios designados a partir de datos de informes de rendimiento guardados'
      ],
      InspectionScheduledReports: [
        'Acceso/Ver: acceso para crear y administrar informes de correo electrónico programados para destinatarios designados a partir de datos de informes de inspección guardados'
      ],
      TrainingScheduledReports: [
        'Acceso/Ver: acceso para crear y administrar informes de correo electrónico programados para destinatarios designados a partir de datos de informes de capacitación guardados'
      ],
      IncidentsScheduledReports: [
        'Acceso/Ver: acceso para crear y administrar informes de correo electrónico programados para destinatarios designados a partir de datos de informes de incidentes guardados'
      ],
      ObservationsScheduledReports: [
        'Acceso/Ver: acceso para crear y administrar informes de correo electrónico programados para destinatarios designados a partir de datos de informes de observaciones guardados'
      ],
      JSAScheduledReports: [
        'Acceso/Ver: acceso para crear y administrar informes de correo electrónico programados para destinatarios designados a partir de datos de informes JSA guardados'
      ],
      SafetyScanScheduledReports: [
        'Acceso/Ver: acceso para crear y administrar informes de correo electrónico programados para destinatarios designados a partir de datos de informes de escaneo de seguridad guardados'
      ]
    },
    EHSSettings: {
      AccountSettings: [
        'Acceso/Ver: acceso para administrar la información de la cuenta y el logotipo del informe de la cuenta'
      ],
      BillingInformation: [
        'Acceso/Ver: acceso para administrar la información de facturación de la cuenta, el contacto principal y la información de pago con tarjeta de crédito'
      ],
      SiteSafetyPlans: [
        'Acceso/Ver: acceso para crear y administrar planes de seguridad específicos del proyecto que contienen requisitos de seguridad, riesgos laborales, partes interesadas del sitio, contratistas y requisitos de PPE durante la duración de un trabajo',
        'Agregar/Editar: capacidad de agregar nuevos planes de seguridad del sitio y actualizar cualquier información del plan existente',
        'Eliminar: capacidad de eliminar planes de seguridad del sitio existentes de la lista de planes'
      ],
      ProcoreIntegration: [
        'Acceso/Ver: acceso para autorizar y sincronizar la cuenta EHS con una cuenta Procore. Cuando tienen éxito, los trabajos de Procore se integran en la plataforma'
      ],
      PlangridIntegration: [
        'Acceso/Ver: acceso para autorizar y sincronizar la cuenta EHS con una cuenta Plangrid. Cuando tienen éxito, los trabajos de Plangrid se integran en la plataforma'
      ],
      EHSLicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo que tienen autorización para usar la aplicación web y móvil de EHS'
      ],
      InspectionLicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo de inspección que tienen autorización para usar la aplicación móvil y web'
      ],
      TrainingLicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo de capacitación que tienen autorización para usar la aplicación móvil'
      ],
      IncidentLicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo de incidentes que tienen autorización para usar la aplicación móvil y web'
      ],
      ObservationLicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo de observación que tienen autorización para usar la aplicación móvil y web'
      ],
      JSALicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo JSA que tienen autorización para usar la aplicación móvil y web'
      ],
      SafetyScanLicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo de escaneo de seguridad que tienen autorización para usar la aplicación móvil y web'
      ],
      FormsLicenseMaintenance: [
        'Acceso/Ver: acceso para ver y administrar todos los usuarios finales del módulo de formularios que tienen autorización para usar la aplicación móvil y web'
      ],
      CompanyMaintenance: [
        'Acceso/Ver: acceso para crear y gestionar todas las empresas vinculadas a registros de seguridad'
      ],
      LocationMaintenance: [
        'Acceso/Ver: acceso para crear y administrar todas las ubicaciones que están vinculadas a registros de seguridad'
      ],
      ObservationLocationMaintenance: [
        'Acceso/Ver: acceso para crear y administrar ubicaciones vinculadas a registros de observación'
      ],
      IncidentsLocationMaintenance: [
        'Acceso/Ver: acceso para crear y administrar ubicaciones vinculadas a registros de incidentes'
      ],
      SafetyScanLocationMaintenance: [
        'Acceso/Ver: acceso para crear y administrar ubicaciones vinculadas a activos de escaneo de seguridad'
      ],
      FormsLocationMaintenance: [
        'Acceso/Ver: acceso para crear y administrar ubicaciones vinculadas a registros de formulario'
      ],
      ResponsiblePartyMaintenance: [
        'Acceso/Ver: acceso para crear y administrar una lista de partes responsables que están vinculadas a ubicaciones o trabajos y a las que se les pueden asignar elementos de acción correctiva'
      ],
      InspectionRPMaintenance: [
        'Acceso/Ver: acceso para crear y administrar una lista de partes responsables de la inspección que están vinculadas a inspecciones o bancos de trabajo y a las que se les pueden asignar elementos de acción correctiva'
      ],
      IncidentsRPMaintenance: [
        'Acceso/Ver: acceso para crear y administrar una lista de partes responsables del incidente que están vinculadas a ubicaciones y a las que se les pueden asignar elementos de acción correctiva'
      ],
      ObservationRPMaintenance: [
        'Acceso/Ver: acceso para crear y administrar una lista de partes responsables de la observación que están vinculadas a las ubicaciones y a las que se les pueden asignar elementos de acción correctiva'
      ],
      SafetyScanRPMaintenance: [
        'Acceso/Ver: acceso para crear y administrar una lista de partes responsables del escaneo de seguridad que están vinculadas a ubicaciones y a las que se les pueden asignar elementos de acción correctiva'
      ],
      FormsRPMaintenance: [
        'Acceso/Ver: acceso para crear y administrar una lista de formularios de partes responsables que están vinculadas a ubicaciones y a las que se les pueden asignar elementos de acción correctiva'
      ],
      'DataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicios web para proteger los datos en formato JSON'
      ],
      'InspectionDataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicio web para extraer datos de inspección en formato JSON'
      ],
      'TrainingDataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicio web para extraer datos de entrenamiento en formato JSON'
      ],
      'IncidentDataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicio web para extraer datos de incidentes en formato JSON'
      ],
      'ObservationsDataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicio web para extraer datos de observación en formato JSON'
      ],
      'JSADataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicios web para extraer datos JSA en formato JSON'
      ],
      'SafetyScanDataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicio web para extraer datos de escaneo de seguridad en formato JSON'
      ],
      'FormsDataExport/API': [
        'Acceso/Ver: acceso para crear administradores de exportación de datos y generar métodos de servicios web para extraer datos de formularios en formato JSON'
      ]
    }
  },
  PLATFORM: {
    PlatformSettings: {
      CompanySettings: [
        'Acceso/Ver: capacidad de abrir y ver el logotipo de la cuenta, los períodos de vencimiento de herramientas y equipos, los requisitos de inicio de sesión y otras configuraciones de la cuenta'
      ],
      MapInventorySummary: [
        'Acceso/Ver: Resumen del inventario en ubicaciones en el mapa de ubicación'
      ],
      CompanyDefaults: [],
      Roles: [
        'Acceso/Ver: capacidad de abrir y ver la página de lista de administración de roles',
        'Agregar/Editar: capacidad de agregar roles nuevos y editar roles existentes con sus usuarios y permisos asociados',
        'Eliminar: posibilidad de eliminar roles si no hay usuarios asignados al rol'
      ],
      DocumentCentral: [
        'Acceso/Ver: capacidad de abrir y ver el repositorio central de documentos',
        'Agregar/Editar: capacidad de agregar archivos o carpetas nuevos y editar los existentes en el repositorio de documentos',
        'Eliminar: capacidad de eliminar archivos o carpetas y moverlos a la papelera de reciclaje central de documentos'
      ],
      DataLoader: [
        'Acceso/Ver: capacidad de acceder al menú de la página del cargador de datos y a las acciones del cargador de datos',
        'Realizar: capacidad de crear una nueva acción del cargador de datos exportando registros de datos y generando una plantilla'
      ],
      CustomFields: [
        'Acceso/Ver: capacidad de acceder al menú de la página de campos personalizados',
        'Agregar/Editar: capacidad de agregar nuevos y editar campos personalizados existentes',
        'Eliminar: capacidad de eliminar campos personalizados existentes'
      ],
      Tasks: [],
      Workflows: [],
      Collaborate: [
        'Acceso/Ver: capacidad de abrir y ver mensajes de Collaborate',
        'Agregar/Editar: capacidad de agregar y editar mensajes en Collaborate'
      ],
      NumberBlocks: [
        'Acceso/Ver: capacidad de abrir y ver la página de numeración',
        'Agregar/Editar: capacidad de agregar y editar datos en los campos numéricos'
      ],
      GlobalListChanges: [],
      GlobalStockpointChanges: [],
      ReinstateTool: [
        'Realizar: capacidad de restablecer herramientas, equipos y materiales nuevamente en el navegador de elementos'
      ],
      ListVisibility: [
        'Acceso/Ver: acceso para ver y administrar ubicaciones de existencias para otorgar visibilidad con selecciones de listas específicas'
      ],
      RecyclingBin: [
        'Acceso/Ver: acceso para ver el contenido en la pestaña de la papelera de reciclaje',
        'Agregar/Editar: capacidad de eliminar herramientas y agregarlas a la papelera de reciclaje, y también ver detalles de los elementos de la papelera de reciclaje',
        'Eliminar: capacidad de eliminar permanentemente elementos en la papelera de reciclaje'
      ]
    },
    ListMaintenance: {
      Certifications: [
        'Acceso/Ver: acceso para ver las certificaciones existentes y su frecuencia de renovación en la página de lista de empresas',
        'Agregar/Editar: capacidad de crear y administrar información de certificación y su frecuencia de renovación',
        'Eliminar: posibilidad de eliminar certificaciones'
      ],
      Contractors: [
        'Acceso/Ver: acceso para ver la información del contratista existente en la página de lista de empresas',
        'Agregar/Editar: capacidad de crear y administrar información del contratista',
        'Eliminar: posibilidad de eliminar contratistas'
      ],
      Customers: [
        'Acceso/Ver: acceso para ver la información del cliente existente en la página de lista de la empresa',
        'Agregar/Editar: capacidad de crear y administrar información del cliente',
        'Eliminar: posibilidad de eliminar clientes'
      ],
      Departments: [
        'Acceso/Ver: acceso para ver los departamentos existentes asignados a los empleados en la página de lista de la empresa',
        'Agregar/Editar: capacidad de crear y administrar información del departamento y su supervisor',
        'Eliminar: posibilidad de eliminar departamentos'
      ],
      Descriptions: [
        'Acceso/Ver: acceso para ver la información de descripción existente en la página de lista de empresas',
        'Agregar/Editar: capacidad de crear y administrar información de descripción',
        'Eliminar: capacidad de eliminar descripciones'
      ],
      Employees: [
        'Acceso/Ver: acceso para ver los empleados existentes y los usuarios de la plataforma en la página de lista de la empresa',
        'Agregar/Editar: capacidad de crear y administrar información de empleados',
        'Eliminar: posibilidad de eliminar empleados'
      ],
      ManagePlatformUsers: [
        'Acceso/Ver: acceso para ver los botones Crear y Administrar cuenta de usuario en la página de información del empleado',
        'Agregar/Editar: capacidad de crear y administrar usuarios de la plataforma a partir de empleados existentes'
      ],
      EmployeeTypes: [
        'Acceso/Ver: acceso para ver los tipos de empleados existentes asignados a los empleados en la página de lista de la empresa',
        'Agregar/Editar: capacidad de crear y administrar información de tipo de empleado',
        'Eliminar: posibilidad de eliminar tipos de empleados'
      ],
      Locations: [
        'Acceso/Ver: acceda para ver las ubicaciones (trabajo) existentes y sin stock en la página de lista de la empresa',
        'Agregar/Editar: capacidad de crear y administrar información de ubicación',
        'Eliminar: posibilidad de eliminar ubicaciones'
      ],
      StockpointDefault: [
        'Acceso/Ver: acceso para ver el contenido en el menú desplegable de ubicación del árbol de organización',
        'Agregar/Editar: capacidad de crear y administrar ubicaciones de existencias para el menú desplegable del árbol organizativo',
        'Eliminar: posibilidad de eliminar ubicaciones de existencias del menú desplegable del árbol organizativo'
      ],
      Manufacturers: [
        'Acceso/Ver: acceso para ver la información del fabricante existente en la página de lista de empresas',
        'Agregar/Editar: capacidad de crear y administrar información del fabricante',
        'Eliminar: posibilidad de eliminar fabricantes'
      ]
    },
    UserSettings: {
      UserPerferences: [
        'Acceso/Ver: acceda para ver la página de configuración en el menú desplegable de perfil de usuario'
      ],
      ResetPassword: [
        'Realizar: capacidad de cambiar su contraseña en la página de configuración del usuario'
      ],
      ManagePlatformUsers: [
        'Acceso/Ver: acceso para ver los botones Crear y Administrar cuenta de usuario en la página de información del empleado',
        'Agregar/Editar: capacidad de crear y administrar usuarios de la plataforma a partir de empleados existentes',
        'Eliminar: capacidad de convertir a los usuarios de la plataforma en empleados'
      ]
    }
  },
  Asset: {
    Inventory: {
      Catalog: [
        'Acceso/Ver: capacidad de acceder y utilizar las funcionalidades del menú del catálogo'
      ],
      ToolModel: [
        'Acceso/Ver: acceso para ver cualquier registro de modelo de herramienta en el catálogo',
        'Agregar/Editar: capacidad de crear nuevos registros de modelo de herramienta y editar campos en el registro de modelo de herramienta del artículo',
        'Eliminar: capacidad de eliminar un registro de modelo de herramienta'
      ],
      MaterialModel: [
        'Acceso/Ver: acceso para ver cualquier registro de modelo de material en el catálogo',
        'Agregar/Editar: capacidad de crear nuevos registros de modelo de material y editar campos en el registro de modelo de material del artículo',
        'Eliminar: capacidad de eliminar un registro de modelo de material'
      ],
      Components: [
        'Acceso/Ver: capacidad de ver los componentes asociados en todos los registros de herramientas del modelo de herramienta',
        'Agregar/Editar: capacidad de agregar y editar herramientas asociadas que está rastreando en la plataforma con este registro de modelo',
        'Agregar/Editar: capacidad de eliminar componentes del registro del modelo de una herramienta, sin eliminar los registros del modelo de herramienta'
      ],
      DataSource: [
        'Acceso/Ver: capacidad de ver los modelos de herramientas que están almacenados en la fuente de datos en línea',
        'Agregar/Editar: capacidad de importar modelos de herramientas desde la fuente de datos en línea a la de su empresa'
      ],
      RepairParts: [
        'Acceso/Ver: capacidad de ver las piezas de reparación que se han asociado con un registro de modelo específico',
        'Agregar/Editar: capacidad de agregar, editar la cantidad requerida y asociar cualquiera de sus materiales y consumibles como piezas de reparación para un registro de modelo específico',
        'Eliminar: capacidad de eliminar cualquier material y consumible de la pestaña de piezas de reparación en un modelo específico',
        'Realizar: capacidad de agregar piezas de reparación en servicio > piezas de reparación en la pantalla de detalles del producto'
      ],
      ItemBrowser: [
        'Acceso/Ver: capacidad de acceder y utilizar las funcionalidades del menú del navegador de elementos'
      ],
      ToolDetail: [
        'Acceso/Ver: acceso para ver cualquier registro de herramienta en el navegador de elementos',
        'Agregar/Editar: capacidad de crear nuevos registros de herramientas, agregar inventario y editar campos en el registro de herramientas del artículo',
        'Eliminar: capacidad de eliminar un registro de herramienta'
      ],
      MaterialDetail: [
        'Acceso/Ver: acceso para ver cualquier registro de material en el navegador de artículos',
        'Agregar/Editar: capacidad de crear nuevos registros de materiales, agregar inventario y editar campos en el registro de materiales del artículo',
        'Eliminar: capacidad de eliminar un registro de material'
      ],
      AdjustInventory: [
        'Realizar: capacidad de ajustar la cantidad de herramientas presentes en una tarea particular'
      ],
      Kits: [
        'Acceso/Ver: capacidad de ver el contenido de un kit en el navegador de artículos',
        'Agregar/Editar: capacidad de crear nuevos kits y cambiar el contenido de un kit en el navegador de artículos',
        'Eliminar: posibilidad de eliminar kits'
      ],
      RetireTool: [
        'Realizar: capacidad de retirar un activo y eliminarlo del explorador de elementos a la página Administrar herramientas retiradas'
      ],
      StockpointSpecific: ['Acceso/Ver: capacidad de explorar, buscar y ver información sobre acciones'],
      LowLevelBrowser: [
        'Acceso/Ver: capacidad de ver herramientas y materiales que se encuentran en el navegador de bajo nivel'
      ],
      ManageRetiredTools: [
        'Acceso/Ver: capacidad de explorar, buscar y ver herramientas retiradas'
      ],
      JobStock: ['Acceso/Ver: capacidad de ver la bolsa de trabajo',
        'Agregar/Editar: capacidad de agregar y editar modelos en Job Stock',
        'Eliminar: capacidad de eliminar modelos del inventario de trabajos'
      ]
    },
    InventoryMovement: {
      PickTicket: [
        'Acceso/Ver: capacidad de ver tickets de recogida abiertos o cerrados',
        'Agregar/Editar: capacidad de crear, abrir y editar tickets de selección existentes en estado de borrador',
        'Eliminar: posibilidad de eliminar un ticket de recogida abierto',
        'Realizar: capacidad de recoger, cerrar y transferir artículos seleccionados desde un ticket de recogida'
      ],
      Transfers: [
        'Acceso/Ver: capacidad de buscar y ver boletos de transferencia cerrados',
        'Eliminar: posibilidad de eliminar tickets de transferencia cerrados',
        'Realizar: capacidad de crear un boleto de transferencia y capacidad de usar el botón de proceso de transferencia en un boleto de transferencia abierto'
      ],
      CountInventory: [
        'Acceso/Ver: capacidad de ver los ajustes del inventario y explorar o buscar tickets de conteo abiertos o cerrados',
        'Agregar/Editar: capacidad de crear, abrir y editar tickets de conteo existentes',
        'Eliminar: posibilidad de eliminar un ticket de conteo existente',
        'Realizar: capacidad de contar artículos en un ticket de conteo y capacidad de publicar ajustes en el inventario'
      ],
      RequisitionsandReturns: [
        'Acceso/Ver: capacidad de acceder a la página del menú de solicitudes y devoluciones',
        'Agregar/Editar: capacidad de crear, abrir y editar solicitudes y devoluciones existentes',
        'Eliminar: capacidad de eliminar una solicitud abierta o una devolución'
      ],
      ShowModelRates: [
        'Acceso/Ver: capacidad de acceder a las tarifas de solicitudes'
      ],
      ShowModelQuantities: [
        'Acceso/Ver: capacidad de acceder a las cantidades de stock de solicitudes'
      ],
      RequisitionBrowser: [
        'Acceso/Ver: capacidad de ver el navegador de solicitudes y las solicitudes en el navegador. Agregar/Editar: Capacidad para editar solicitudes. Eliminar: Posibilidad de eliminar Solicitudes.'
      ]
    },
    'Service&Calibration': {
      ServiceBrowser: [
        'Acceso/Ver: capacidad de acceder y utilizar las funcionalidades del menú del navegador de servicios'
      ],
      ServiceLog: [
        'Acceso/Ver: capacidad de ver solicitudes de servicio abiertas o cerradas',
        'Agregar/Editar: capacidad de crear y editar solicitudes de servicio existentes en estado pendiente',
        'Eliminar: capacidad de eliminar una solicitud de servicio abierta',
        'Realizar: capacidad de convertir una solicitud de servicio en una orden de trabajo'
      ],
      ServiceEvent: [
        'Acceso/Ver: capacidad de ver eventos de servicio existentes',
        'Agregar/Editar: capacidad de crear y editar eventos de servicio existentes',
        'Eliminar: capacidad de eliminar eventos de servicio',
        'Realizar: capacidad de guardar un nuevo evento de servicio y capacidad de agregar un evento desde el modelo'
      ],
      ServiceTicket: [
        'Acceso/Ver: capacidad de ver órdenes de trabajo abiertas o cerradas',
        'Agregar/Editar: capacidad de crear nuevas y editar órdenes de trabajo existentes',
        'Eliminar: capacidad de eliminar órdenes de trabajo',
        'Realizar: capacidad de guardar y cerrar una orden de trabajo'
      ],
      ServiceSchedule: [
        'Acceso/Ver: capacidad de ver eventos abiertos o programados',
        'Agregar/Editar: capacidad de crear programas de servicio nuevos y editar los existentes',
        'Eliminar: capacidad de eliminar horarios de servicio',
        'Realizar: capacidad de guardar y cerrar horarios de servicio'
      ]
    },
    Purchasing: {
      PurchaseOrders: [
        'Acceso/Ver: capacidad de ver órdenes de compra abiertas o cerradas',
        'Agregar/Editar: capacidad de crear órdenes de compra nuevas y editar las existentes en estado de borrador',
        'Eliminar: capacidad de eliminar órdenes de compra abiertas o cerradas',
        'Realizar: capacidad de utilizar el botón de recibir orden de compra'
      ],
      POTicket: [
        'Acceso/Ver: capacidad de abrir órdenes de compra para ver sus detalles',
        'Agregar/Editar: capacidad de crear nuevas órdenes de compra en estado de borrador',
        'Eliminar: posibilidad de eliminar órdenes de compra',
        'Realizar: capacidad de guardar una orden de compra'
      ],
      ProductVendors: [],
      PricingPlans: [],
      ReceivingTickets: [
        'Acceso/Ver: capacidad de ver tickets de recepción cerrados'
      ],
      ReceivingTicket: [
        'Acceso/Ver: capacidad de ver un ticket de recepción abierto o cerrado y la capacidad de abrir la orden de compra, asociada con el ticket de recepción',
        'Agregar/Editar: capacidad de recibir contra una orden de compra, crear un nuevo ticket de recepción y editar un ticket de recepción abierto',
        'Realizar: capacidad de recibir artículos en su inventario'
      ]
    },
    'JobCost&Billing': {
      'Billing&InvoiceBrowser': [
        'Acceso/Ver: capacidad de acceder y utilizar las funciones del menú de facturación y costos del trabajo'
      ],
      BillingBrowser: [
        'Acceso/Ver: capacidad de abrir y ver la página del navegador de facturación'
      ],
      'CalculateJobCost&Billing': [
        'Realizar: capacidad de utilizar el botón calcular para calcular cargos no publicados'
      ],
      'MiscCharge/Credit': [
        'Acceso/Ver: capacidad de ver cargos o créditos diversos no publicados existentes',
        'Agregar/Editar: capacidad de crear nuevos cargos o créditos diversos no publicados y editar los existentes',
        'Eliminar: posibilidad de eliminar cargos o créditos varios existentes no contabilizados'
      ],
      'PostJobCost&Billing': [
        'Realizar: capacidad de publicar cargos existentes no publicados'
      ],
      RateSheets: [
        'Acceso/Ver: capacidad de ver materiales y consumibles existentes y hojas de tarifas de herramientas y equipos',
        'Agregar/Editar: capacidad de crear hojas de tarifas de materiales y consumibles, herramientas y equipos nuevos y editar los existentes',
        'Eliminar: capacidad de eliminar materiales y consumibles existentes y hojas de tarifas de herramientas y equipos'
      ],
      CompanyCalendar: [
        'Acceso/Ver: capacidad de abrir y ver la página del calendario de la empresa',
        'Agregar/Editar: posibilidad de editar el calendario de la empresa'
      ],
      'Calendar-CostCenter': [
        'Acceso/Ver: capacidad de abrir y ver la página del calendario de la empresa',
        'Agregar/Editar: posibilidad de editar el calendario de la empresa'
      ],
      HourlyUsageLog: [
        'Acceso/Ver: capacidad de abrir y ver el registro de uso del equipo',
        'Agregar/Editar: capacidad de agregar herramientas y equipos al registro de uso del equipo y editar líneas en el registro de uso del equipo',
        'Eliminar: capacidad de eliminar herramientas y equipos del registro de uso del equipo'
      ],
      'ExportJobCost&Billing': [
        'Realizar: capacidad de utilizar el botón exportar datos en el módulo de facturación y costos del trabajo'
      ]
    },
    Scheduling: {
      SchedulingRate: [
        'Acceso/Ver: la tarifa de un modelo al solicitar programación'
      ],
      SchedulingAvailability: [
        'Acceso/Ver: la disponibilidad de un modelo al solicitar programación'
      ],
      SchedulingBrowser: [
        'Acceso/Ver: la tarifa de un modelo al solicitar programación'
      ],
      SchedulingCalendar: [
        'Acceso/Ver: la disponibilidad de un modelo al solicitar programación'
      ],
      SchedulingRequest: [
        'Acceso/Ver: la disponibilidad de un modelo al solicitar programación'
      ]
    },
    'Reports&Graphs': {
      Reports: [
        'Acceso/Ver: acceso para generar/descargar varios informes y aplicar filtros para capturar datos de herramientas únicos'
      ],
      ToolReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de herramientas'
      ],
      ListReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de lista'
      ],
      AssignmentReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de transferencia'
      ],
      BarCodeLabels: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para los informes de etiquetas de códigos de barras'
      ],
      ConsumableReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de consumibles'
      ],
      PurchasingReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de compras'
      ],
      ServiceReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de servicio'
      ],
      BillingReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de facturación'
      ],
      TasksReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de Tareas'
      ],
      FuelReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes de combustible'
      ],
      GraphReports: [
        'Acceso/Ver: capacidad de ejecutar, imprimir, PDF y/o exportar datos para informes gráficos'
      ],
      CustomReports: [
        'Acceso/Ver: capacidad de ver y generar informes de lista personalizados',
        'Eliminar: capacidad de eliminar informes de listas personalizadas existentes'
      ],
      ScheduledReports: [
        'Acceso/Ver: capacidad de ver el menú de la página de informes programados',
        'Agregar/Editar: capacidad de agregar informes programados nuevos y editar los existentes para usuarios asignados con una frecuencia de correo electrónico determinada para informes específicos',
        'Eliminar: capacidad de eliminar informes programados existentes'
      ],
      CustomDashboards: []
    },
    Tracking: {
      LocationMap: [
        'Acceso/Ver: capacidad de ver el mapa de ubicación'
      ],
      FuelCards: [
        'Acceso/Ver: capacidad de acceder a las tarjetas de combustible desde el menú de navegación'
      ],
      WirelessGateways: [
        'Acceso/Ver: capacidad de acceder y ver puertas de enlace inalámbricas',
        'Agregar/Editar: capacidad de agregar puertas de enlace inalámbricas nuevas o editar las existentes',
        'Eliminar: posibilidad de eliminar puertas de enlace inalámbricas'
      ],
      WirelessTags: [
        'Acceso/Ver: capacidad de acceder y ver etiquetas inalámbricas',
        'Agregar/Editar: capacidad de agregar etiquetas inalámbricas nuevas o editar las existentes',
        'Eliminar: posibilidad de eliminar etiquetas inalámbricas'
      ]
    },
    Settings: {
      Categories: [
        'Acceso/Ver: acceso para ver las categorías de herramientas, materiales o plantillas existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar categorías de herramientas, materiales o plantillas',
        'Eliminar: posibilidad de eliminar categorías de herramientas, materiales o plantillas'
      ],
      Classes: [
        'Acceso/Ver: acceso para ver las clases existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar clases',
        'Eliminar: posibilidad de eliminar clases'
      ],
      StatusCodes: [
        'Acceso/Ver: acceda para ver los códigos de estado existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar códigos de estado',
        'Eliminar: capacidad de eliminar códigos de estado'
      ],
      ShippingMethods: [
        'Acceso/Ver: acceda para ver los métodos de envío existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar métodos de envío',
        'Eliminar: posibilidad de eliminar métodos de envío'
      ],
      UnitsofMeasure: [
        'Acceso/Ver: acceso para ver las unidades de medida existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar unidades de medida',
        'Eliminar: capacidad de eliminar unidades de medida'
      ],
      Vendors: [
        'Acceso/Ver: acceso para ver los proveedores existentes en la página de lista de configuración',
        'Agregar/Editar: capacidad de crear y administrar proveedores',
        'Eliminar: capacidad de eliminar proveedores'
      ],
      CostCenters: [
        'Acceso/Ver: acceda para ver los centros de costos existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar centros de costos',
        'Eliminar: posibilidad de eliminar centros de costos'
      ],
      CostCodes: [
        'Acceso/Ver: acceda para ver los códigos de costos existentes en la página de lista de configuraciones',
        'Agregar/Editar: capacidad de crear y administrar códigos de costos',
        'Eliminar: capacidad de eliminar códigos de costo'
      ],
      DataMergeUtility: [
        'Realizar: capacidad de fusionar datos que están disponibles en la ventana Fusión de datos '
      ]
    }
  }
}

export default platformPermissionsTooltip
